import { DOCUMENT_ROOT, STORAGE_KEY } from '../config';
import axios from 'axios';
import sessionUtils from '../utils/sessionUtils';
import SessionApi from './sessionApi';

export const instance = axios.create();

function createAxiosResponseInterceptor() {
  instance.interceptors.request.use(config => {
    const token = sessionUtils.getToken(STORAGE_KEY.access_token);
    if (token) {
      config.headers['Authorization'] = token;
      config.headers['Content-Type'] = 'application/json';
    }
    return config;
  },
    error => {
      Promise.reject(error)
    });

  const interceptor = instance.interceptors.response.use(function (resp) {
    // Eating up here
    return resp;
  }, function (error) {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    if (error && typeof error.response !== 'undefined' && error.response.status === 400) {
      return Promise.reject(error);
    }
    if (error && typeof error.response === 'undefined') {
      return Promise.reject(error);
    }
    // Reject promise if usual error
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    /*
    * When response code is 401, try to refresh the token.
    * Eject the interceptor so it doesn't loop in case
    * token refresh causes the 401 response
    */
    instance.interceptors.response.eject(interceptor);
    return SessionApi.getRefreshToken().then(response => {
      sessionUtils.saveToken(STORAGE_KEY.access_token, `Bearer ${response.data['access']}`);
    }).catch(err => {
      if (err.response && err.response.status !== 401) {
        sessionUtils.flushToken(STORAGE_KEY.refresh_token);
        window.location = DOCUMENT_ROOT;
      } else {
        window.location = DOCUMENT_ROOT;
      }
      sessionUtils.flushToken(STORAGE_KEY.access_token);
      sessionUtils.flushToken(STORAGE_KEY.refresh_token);
      return Promise.reject(err);
    }).finally(createAxiosResponseInterceptor);
  });
}

createAxiosResponseInterceptor();

export default instance;
