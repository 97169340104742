import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../../styles/modalComponent.scss';
import '../../styles/datasetColumnDetailsComponent.scss';
import '../../styles/form.scss';
import HgButtonComponent from './hgButtonComponent';
import DatasetColumnDetailsComponent from './datasetColumnDetailsComponent';

function DatasetColumnDetailsPopComponent(props) {
    const { datasetSchema, toggle, modal, onAccept } = props;

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} scrollable={true} size={"md"} {...props}>
                <ModalHeader toggle={toggle}>Column Details</ModalHeader>
                <ModalBody>
                    <DatasetColumnDetailsComponent datasetSchema={datasetSchema} />
                </ModalBody>
                <ModalFooter>
                    <HgButtonComponent buttonText={"Close"} isPrimary={false} onclickHandler={() => onAccept()} />
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default DatasetColumnDetailsPopComponent;