import { useEffect, useState } from "react";
import { SIDEBAR_TABS } from "../config";
import { ImgCrossRounded, ImgTBBCLogo } from "../images";
import "../styles/productListingComponent.scss";
import ImgTitleComponent from "./ImgTitleComponent";
import DatasetPreviewComponent from "./pure/datasetPreviewComponent";
import GridViewDatasetComponent from "./pure/gridViewDatasetComponent";
import SidebarComponent from "./pure/sidebarComponent";
import ShowMoreButton from "./showMoreButton";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import LLicenseComponent from "./licenseComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getRootDatasetInfo,
  getRootDatasetsList,
} from "../actions/datasetActions";
import axios from "axios";
import styled from "styled-components";
import ListViewDatasetComponent from "./pure/listViewDatasetComponent";
import { GoChevronRight } from "react-icons/go";

const infoDummyTagList = [
  "Measurement Data",
  "Data Integration",
  "Climate Chnage",
  "NOAA",
  "Weather",
  "Event-Based Data",
  "Data-Analytics",
];

const GnuCardComponent = ({ toggle }) => {
  return (
    <div className="gnu-card">
      <div className="top-section">
        <p className="gnu-title">GNU Public License 2.0</p>
        <button className="license-text" onClick={toggle}>
          SEE LICENSE & TERMS
        </button>
      </div>
      <div className="lower-section">
        <div className="add-to-datasets-text">Add to Your Datasets</div>
      </div>
    </div>
  );
};

const ProductListingComponent = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [preview_table_header, setPreview_table_header] = useState([]);
  const [preview_table_data, setPreview_table_data] = useState([]);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [myDatasets, setMyDatasets] = useState([]);
  const [isListView, setIsListView] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRootDatasetsList());
  }, []);

  const datasets = useSelector((state) => state.dataset.rootDatasets);

  useEffect(() => {
    console.log(datasets, "datasets are");
    const uid = datasets?.results[0]?.uid;
    dispatch(
      getRootDatasetInfo(uid, (previewUrl, Schema_Url) => {
        getPreview(previewUrl);
        getDatasetSchema(Schema_Url);
      })
    );
  }, [datasets]);

  useEffect(() => {
    console.log("Header and data", preview_table_data, preview_table_header);
  }, [preview_table_data, preview_table_header]);

  const getPreview = (url) => {
    if (url) {
      // this.setState({ isPreviewLoading: true });
      axios
        .get(url)
        .then(({ data }) => {
          setPreview_table_header(Object.keys(data[0]));
          setPreview_table_data([...data]);
          // this.setState({ preview_table_header: Object.keys(data[0]), preview_table_data: [...data] });
        })
        .catch((error) => {
          setPreview_table_header(Object.keys([]));
          setPreview_table_data([]);
          // this.setState({ preview_table_header: [], preview_table_data: [] });
        })
        .finally(() => {
          setIsPreviewLoading(false);
          // this.setState({ isPreviewLoading: false });
        });
    } else {
    }
  };

  const toggleDescription = () => {
    console.log("toggle done");
    const ele = document.getElementsByClassName("product-more-dataset-description")[0];
    ele.classList.toggle("product-show-more");
  };

  const getDatasetSchema = (url) => {
    if (url) {
      this.setState({ isColumnDetailsLoading: true });
      axios
        .get(url)
        .then(({ data }) => {
          this.setState({ datasetSchema: data });
        })
        .catch((error) => {
          this.setState({ datasetSchema: [] });
        })
        .finally(() => {
          this.setState({ isColumnDetailsLoading: false });
        });
    } else {
    }
  };
  return (
    <>
      <div className="product-header-div">
        <img src={ImgTBBCLogo} />
      </div>
      <div className="w-100 d-flex">
        <div className="product-sidebar-section">
          <SidebarComponent activeTab={SIDEBAR_TABS.myDataSet} />
        </div>
        <div className="product-main-content">
          <div className="product-title-section">
            Ocean of Data / Measurement Data/ Institutional Data/ Climate Change
          </div>
          <div className="product-lower-main">
            <div className="product-lower-left-main">
              <ImgTitleComponent />
              <div className="product-dataset-info">
                <p className="product-dataset-title">Dataset Description</p>
                <p className="product-dataset-description">
                  Hurricane Event-Based Data includes detailed meteorological
                  and oceanographic data collected during and after hurricanes,
                  supporting research on storm impacts, resilience, and climate
                  trends.
                  <span className="product-more-dataset-description">
                    This is a dummy text to test show more
                    functionality.Hurricane Event-Based Data includes detailed
                    meteorological and oceanographic data collected during and
                    after hurricanes, supporting research on storm impacts,
                    resilience, and climate trends.This is a dummy text to test
                    show more functionality.Hurricane Event-Based Data includes
                    detailed meteorological and oceanographic data collected
                    during and after hurricanes, supporting research on storm
                    impacts, resilience, and climate trends.
                  </span>
                </p>
                <ShowMoreButton
                  toggle={toggleDescription}
                  content="Show More"
                />
                <div className="small-seperation-line"></div>
              </div>
              <div className="product-dataset-info">
                <p className="product-dataset-title">Information Categories</p>
                <div className="product-information-categories-tags-container">
                  {infoDummyTagList.map((item) => {
                    return <span className="product-tag-item m-1">{item}</span>;
                  })}
                </div>
                <ShowMoreButton content="Show More" />
                <div className="small-seperation-line"></div>
              </div>

              <div className="product-dataset-info license">
                <div className="d-flex justify-content-between">
                  <span>
                    <p className="product-dataset-title">License</p>
                    <p className="">Open Access (public domain)</p>
                  </span>
                  <div className="product-view-license-button-container">
                    <ShowMoreButton toggle={toggle} content="View License" />
                  </div>
                </div>
                <div className="small-seperation-line"></div>
              </div>

              <div className="product-dataset-info">
                <p className="product-dataset-title">Dataset Overview</p>
                <div className="product-cards-description">
                  <div className="col-3 product-card">
                    <p className="title">DATA TYPE</p>
                    <p className="desc">CSV</p>
                  </div>
                  <div className="mini-card product-card">
                    <p className="title">SIZE</p>
                    <p className="desc">94 MB</p>
                  </div>
                  <div className="mini-card product-card">
                    <p className="title">ROWS</p>
                    <p className="desc">794</p>
                  </div>
                  <div className="mini-card product-card">
                    <p className="title">COLUMNS</p>
                    <p className="desc">1378</p>
                  </div>
                  <div className="mini-card product-card">
                    <p className="title">CREATED</p>
                    <p className="desc">01/03/23</p>
                  </div>
                  <div className="mini-card product-card">
                    <p className="title">UPDATED</p>
                    <p className="desc">09/03/24</p>
                  </div>
                </div>
                <p className="product-dataset-title">Dataset Sample</p>
                <div className="product-preview-container mb-4">
                  <DatasetPreviewComponent
                    preview_table_data={preview_table_data}
                    preview_table_header={preview_table_header}
                  />
                </div>
                <ShowMoreButton
                  content="Add Sample to Datasets"
                  bgColor="rgba(213, 228, 246, 1)"
                />
                <div className="small-seperation-line"></div>
              </div>

              <div className="small-seperation-line"></div>
            </div>
            <div className="lower-right-main">
              <div className="gnu-public-license">
                <GnuCardComponent toggle={toggle} />
              </div>
              {/* <div className="gnu-description"> <div><img src="./dot.png"/></div><div className="">A dismissable Heads Up details with important app purchase info, is located in this sub window.</div></div> */}
              <div className="contact-section">
                <p>Help</p>
                <ul>
                  <li>Contact Sales</li>
                  <li>Documentation</li>
                  <li>Terms and Conditions</li>
                  <li>FAQ</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="product-lower-container">
      <div className="product-datasets">
          <div className="product-heading">Popular Datasets</div>
          <div className="product-view-all">
            <div className="product-view-all-text">View All</div>
            <div className="product-next-icon">
              <GoChevronRight />
            </div>
          </div>
        </div>
        {!isListView ? (
          <div className="product-popular-datasets-grid">
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
          </div>
        ) : (
          <div className="product-popular-datasets-list">
            <ListViewDatasetComponent />
            <ListViewDatasetComponent />
          </div>
        )}
        {!isListView ? (
          <div className="product-popular-datasets-grid">
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
          </div>
        ) : (
          <div className="product-popular-datasets-list">
            <ListViewDatasetComponent />
            <ListViewDatasetComponent />
          </div>
        )}
       {!isListView && <div className="product-seperation-line"></div>}
        <div className="product-datasets">
          <div className="product-heading">All Datasets</div>
          <div className="product-view-all">
            <div className="product-view-all-text">View All</div>
            <div className="product-next-icon">
              <GoChevronRight />
            </div>
          </div>
        </div>
        {!isListView ? (
          <div className="product-popular-datasets-grid">
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
          </div>
        ) : (
          <div className="product-popular-datasets-list">
            <ListViewDatasetComponent />
            <ListViewDatasetComponent />
          </div>
        )}
         {!isListView ? (
          <div className="product-popular-datasets-grid">
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
          </div>
        ) : (
          <div className="product-popular-datasets-list">
            <ListViewDatasetComponent />
            <ListViewDatasetComponent />
          </div>
        )}
         {!isListView ? (
          <div className="product-popular-datasets-grid">
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
          </div>
        ) : (
          <div className="product-popular-datasets-list">
            <ListViewDatasetComponent />
            <ListViewDatasetComponent />
          </div>
        )}
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        style={{
          width: "540px",
          height: "fit-content",
        }}
        className={"modal-container"}
      >
        <ModalBody
          style={{
            width: "600px",
            height: "fit-content",
            margin: "0",
            display: "flex",
            flexDirection: "column",
            padding: "1em",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3)",
          }}
        >
          <div className="align-self-end">
            <button style={{ border: "none", padding: "0" }} onClick={toggle}>
              <img
                style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}
                src={ImgCrossRounded}
              />
            </button>
          </div>
          <LLicenseComponent />
        </ModalBody>
      </Modal>
    </>
  );
};

export default ProductListingComponent;
