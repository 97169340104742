import React, { useEffect, useState } from 'react';
import { ImgTrash, ImgPlusDark } from '../../images';
import '../../styles/hgTagsComponent.scss';

export const HgTagsComponent = (props) => {
    const { tagsChangeHandler, defaultTags } = props;
    const [tagsList, updateTagsList] = useState([]);
    const [showInputKeyword, setShowInputKeyword] = useState(false);
    const [tagValue, setTagValue] = useState('');
    const [validate, setValdate] = useState(false);

    const updateTagValue = (e) => {
        setTagValue(e.target.value);
    }

    const keyDown = (e) => {
        if ((e.keyCode === 13 || e.key === 'Enter') && tagValue.trim()) {
            addTag(tagValue.toLowerCase());
        }
    }

    const addTag = (tagValue) => {
        setValdate(true);
        const itemExist = tagsList.find(tag => {
            return tag.toLowerCase() === tagValue.toLowerCase()
        });
        if (!itemExist) {
            updateTagsList([...tagsList, tagValue]);
            setShowInputKeyword(false);
            setTagValue('');
        } else {
            // setChipExists(true);
        }
    }

    const removeTag = (index) => {
        setValdate(true);
        const filteredtags = tagsList.filter((chip, i) => {
            return index !== i;
        });
        updateTagsList(filteredtags);
    }

    useEffect(() => {
        if (validate) {
            tagsChangeHandler(tagsList);
        }
    }, [tagsList]);

    useEffect(() => {
        if (defaultTags) {
            updateTagsList([...defaultTags]);
            tagsChangeHandler(defaultTags);
        }
    }, [defaultTags]);

    return (
        <div className="keywords-list">
            <ul>
                {tagsList.map((item, i) => {
                    return <li key={`${item}${i}`} >{item} <img src={ImgTrash} alt="delete" onClick={() => removeTag(i)} /></li>
                })}
                {!showInputKeyword
                    ? <li className="add-new-keyword" onClick={() => setShowInputKeyword(!showInputKeyword)}>Add new keyword<img src={ImgPlusDark} alt="Add new keyword" /></li>
                    : <>
                        <input type="text"
                            className="tags-input"
                            placeholder="enter here..."
                            onChange={updateTagValue}
                            onKeyDown={keyDown}
                            value={tagValue}
                            autoFocus />
                        <div className="input-toggler" onClick={() => setShowInputKeyword(false)}></div>
                    </>
                }
            </ul>
        </div>
    )
}

export default HgTagsComponent;

