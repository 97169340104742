import moment from 'moment';
import _ from 'lodash';

export function abbreviateNumber(number) {
  const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
  const tier = Math.log10(number) / 3 | 0;

  if (tier == 0) return number;

  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;
  const scaledArr = scaled.toFixed(1).split('.')
  const formattedScaled = scaledArr[1] == 0 ? scaledArr[0] : scaled.toFixed(1);
  return formattedScaled + suffix;
}

export function secondsToHoursMinutes(fullSeconds, shortened = true, showSeconds = false) {
  fullSeconds = parseInt(fullSeconds, 10);
  const hours = Math.floor(fullSeconds / 3600);
  const minutes = Math.floor((fullSeconds - (hours * 3600)) / 60);
  const seconds = fullSeconds - (hours * 3600) - (minutes * 60);
  let secondsDisplay = '', hoursDisplay = '', minutesDisplay = '';

  if (shortened) {
    hoursDisplay = hours > 0 ? hours + "h" : false;
    minutesDisplay = minutes > 0 ? minutes + "m" : false;
    secondsDisplay = seconds > 0 ? seconds + "s" : false;
  } else {
    hoursDisplay = hours > 0 ? hours + (hours == 1 ? " hr" : " hrs ") : false;
    minutesDisplay = minutes > 0 ? minutes + (minutes == 1 ? " min" : " mins") : false;
    secondsDisplay = seconds > 0 ? seconds + (seconds == 1 ? " sec" : " secs") : false;
  }

  if (hours == 0 && minutes == 0) {
    if (secondsDisplay) {
      return secondsDisplay;
    } else {
      return '';
    }
  } else {
    let hoursMinutesSeconds = '';

    if (hoursDisplay) {
      hoursMinutesSeconds += hoursDisplay;
    }

    if (minutesDisplay) {
      if (hoursDisplay) {
        hoursMinutesSeconds += ' ';
      }

      hoursMinutesSeconds += minutesDisplay;
    }

    if (showSeconds && secondsDisplay) {
      if (minutesDisplay) {
        hoursMinutesSeconds += ' ';
      }

      hoursMinutesSeconds += secondsDisplay;
    }

    return hoursMinutesSeconds;
  }
}

export function calculateTimestampDifference(timestamp, offset, returnAs = 'days') {
  if (_.isNil(timestamp) || _.isNil(offset)) {
    return 'nil';
  }

  const offsetParam = '+' + offset.split('+')[1];
  const now = moment().utcOffset(offsetParam);
  const momentTimestamp = moment.unix(timestamp);
  const duration = moment.duration(now.diff(momentTimestamp));
  if (returnAs === 'days') {
    return Math.round(duration.asDays());
  }
}

export function timeAgo(currentTimestamp, timestamp) {
  const currentDate = new Date(currentTimestamp * 1000);
  const date = new Date(timestamp * 1000);
  const times = [['second', 1], ['minute', 60], ['hour', 3600], ['day', 86400], ['week', 604800], ['month', 2592000], ['year', 31536000]];
  let diff = Math.round((currentDate - date) / 1000);

  for (let t = 0; t < times.length; t++) {
    if (diff < times[t][1]) {
      if (t == 0) {
        return 'Just now';
      } else {
        diff = Math.round(diff / times[t - 1][1]);
        return diff + ' ' + times[t - 1][0] + (diff == 1 ? ' ago' : 's ago');
      }
    }
  }
}

/**
 * @function getAge Calculate age from current time and DOB timestamp
 * @param {Date} timestamp 
 * @returns Age in Years/Months
 */
export function getAge(timestamp) {
  try {
    const now = moment();
    const dob = moment.unix(timestamp);
    const age = now.diff(dob, 'years');
    if (age === 0) {
      return `${now.diff(dob, 'Months')} mos`
    }
    return `${now.diff(dob, 'years')} yrs`;
  } catch (e) {
    console.log("Exception: getAge function ", e);
    return 'NA';
  }
}

/**
 * @function getLastSeen
 * @param {Date} timestamp 
 * @returns Last seen
 */
export function getLastSeen(timestamp) {
  try {
    if (timestamp) {
      const now = moment();
      const lastSeenTime = moment(timestamp);
      const timeDiff = now.diff(lastSeenTime, 'minutes');
      if (timeDiff < 1) {
        return `Last seen at ${moment(timestamp).format("h:mm A")}`;
      } else if (timeDiff >= 1 && timeDiff < 60) {
        return `Last seen ${timeDiff} min ago`;
      } else if (timeDiff >= 60 && timeDiff < 1440) {
        return `Last seen at ${moment(timestamp).format("h:mm A")}`
      } else {
        return `Last seen at ${moment(timestamp).format("lll")}`
      }
    } else {
      return "";
    }
  } catch (e) {
    console.log("Exception in last seen: ", e);
    return "";
  }
}

/**
 * @function getFileSize
 * @param {bytes} size 
 * @returns size in MB or GB
 */
export function getFileSize(size) {
  const calculatedSize = size / (1024 * 1024);
  if (calculatedSize > 1000) {
    return `${(calculatedSize / 1024).toFixed(2)} GB`;
  } else {
    return `${calculatedSize.toFixed(2)} MB`;
  }
}
