import React, { useState } from 'react';
import { ImgCloseRed, ImgWarningWhite } from '../../images';
import '../../styles/formValidationErrorContainer.scss';

function FormValidationErrorContainer(props) {
    const { errMessage, style, isPermanent } = props;
    const [hideMessage, setHideMessage] = useState(false);
    return (
        <>
            <div className={hideMessage ? "errorMsgnew hide" : "errorMsgnew"} style={style}>
                {/* <img src={ImgWarningWhite} width="15" height="14" /> */}
                {errMessage}
                {!isPermanent && <img src={ImgCloseRed} onClick={() => setHideMessage(true)} />}
            </div>
        </>
    )
} 

export default FormValidationErrorContainer;
