import React, { Component } from 'react';
import '../styles/loginComponent.scss';
import '../styles/commonStyle.scss';
import '../styles/form.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as accountActions from '../actions/accountActions';
import { DOCUMENT_ROOT } from '../config';
import FormValidationErrorContainer from './pure/formValidationErrorContainer';
import { ImgLoginBgEclipse, ImgNlg, ImgOMTLogo, ImgTBBCLogo, ImgWelcomeTBBC, Logo } from '../images';
import Loader from './loaderComponent';
import { FormUtils } from '../utils/formUtils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

class SetResetPasswordComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirmPassword: '',
            passwordValid: true,
            confirmPasswordValid: true,
            passwordErrorMessage: 'Enter your password',
            confirmPasswordErrorMessage: 'Please confirm password',
            passwordRules: {
                minLength: false,
                hasSymbols: false,
                hasBothCases: false,
                hasNumber: false
            },
            PasswordVisible: false
        }
    }

    componentWillUnmount() {
        this.props.actions.accountStateReset();
    }

    onBlur = (e) => {
        const { name, value } = e.target;
        this.validateField(name, value);
    }

    onChange = (e) => {
        const { name, value } = e.target;
        this.validateField(name, value);
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            const {token} = this.props.match.params;
            this.props.actions.setResetPassword(this.state.password, this.state.confirmPassword, token);
        }
    }

    handleEyeClick = () => {
        this.setState((prevState) => ({ PasswordVisible: !prevState.PasswordVisible }));
    }


    validateField = (name, value) => {
        if (name === 'confirmPassword') {
            const { fieldValid, errorMessage } = FormUtils.validateFormField(name, value, this.state.password);
            this.updateFormState(name, value, fieldValid, errorMessage);
        } else if (name === 'password') {
            const { fieldValid, errorMessage, comparedValueValid } = FormUtils.validateFormField(name, value, this.state.confirmPassword);
            this.updateFormState(name, value, fieldValid, errorMessage, comparedValueValid);
        } else {
            const { fieldValid, errorMessage } = FormUtils.validateFormField(name, value);
            this.updateFormState(name, value, fieldValid, errorMessage);
        }
    }

    updateFormState = (name, value, fieldValid, errorMessage, comparedValueValid = true) => {
        const state = {
            [name]: value,
            [name + 'Valid']: fieldValid,
            [name + 'ErrorMessage']: errorMessage
        }
        if (name === 'password') {
            state['confirmPasswordValid'] = comparedValueValid;
        }
        this.setState(state);
    }

    validateForm = () => {
        if ((this.state.password.length > 0 && this.state.passwordValid)
            && (this.state.confirmPassword.length > 0 && this.state.confirmPasswordValid)) {
            return true;
        } else {
            if (this.state.password.length == 0) {
                this.setState({
                    passwordValid: false,
                    passwordErrorMessage: 'Enter new password'
                });
            }

            if (this.state.confirmPassword.length == 0) {
                this.setState({
                    confirmPasswordValid: false,
                    confirmPasswordErrorMessage: 'Enter confirm password'
                });
            }

        }
    }

    onCancelHandler = () => {
        this.props.actions.accountStateReset();
        this.props.history.push(DOCUMENT_ROOT);
    }

    render() {
        return (
            <div className="login-component">
                {/* <header className="header">
                    <Link to={'/'}><img src={Logo} /></Link>
                </header> */}
                <main className="main">
                    {this.props.isLoading && <Loader />}
                    {!this.props.isSetResetPasswordSuccess
                        ? <>
                            <div style={{marginBottom: '24px'}}><img src={ImgTBBCLogo}/></div>
                            <div className="section-heading">SET NEW PASSWORD</div>
                            <div className="helper-text">Enter new password and click on save button.</div>
                            <form className="dsg-form" onSubmit={this.onSubmit} noValidate={false}>
                                <div className="form-field">
                                <div className="form-field-label mui">create password</div>
                                    <input type={this.state.PasswordVisible ? "text" : "password"}
                                        id="password"
                                        name="password"
                                        className="form-control"
                                        placeholder="enter new password"
                                        onBlur={this.onBlur}
                                        onChange={this.onChange}
                                        value={this.state.password} />
                                    {/* <span className="form-icon password"></span> */}
                                    <span className="password-toggle-icon" onClick={this.handleEyeClick}>
                                        {this.state.PasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </span>
                                    {!this.state.passwordValid && <FormValidationErrorContainer errMessage={this.state.passwordErrorMessage} isPermanent={true} />}
                                </div>
                                <div className="form-field">
                                <div className="form-field-label mui">confirm password</div>
                                    <input type={this.state.PasswordVisible ? "text" : "password"}
                                        id="conf-password"
                                        name="confirmPassword"
                                        className="form-control"
                                        placeholder="confirm password"
                                        onBlur={this.onBlur}
                                        onChange={this.onChange}
                                        value={this.state.confirmPassword} />
                                    {/* <span className="form-icon password"></span> */}
                                    <span className="password-toggle-icon" onClick={this.handleEyeClick}>
                                        {this.state.PasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </span>
                                    {!this.state.confirmPasswordValid && <FormValidationErrorContainer errMessage={this.state.confirmPasswordErrorMessage} isPermanent={true} />}
                                </div>
                                <button className="submit" type="submit" disabled={this.props.isLoading}>Save</button>
                                {this.props.formError && <FormValidationErrorContainer errMessage={this.props.formError} />}
                            </form>
                        </>
                        : <>
                            <div className="section-heading">Password Updated Successfully!</div>
                            <div className="helper-text"><span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={this.onCancelHandler}>Click here to login.</span></div>
                        </>
                    }
                </main>
                <img src={ImgWelcomeTBBC} className="welcome-tbbc" />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.account.isSetResetPasswordLoading,
        isSetResetPasswordSuccess: state.account.isSetResetPasswordSuccess,
        formError: state.account.formError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(accountActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetResetPasswordComponent);