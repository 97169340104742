import countryData from "./country.json";

export const DOCUMENT_ROOT = "/";

const { REACT_APP_API_URL, REACT_APP_OMT_ANALYTICS_API_URL } = process.env;
export const API_URL = REACT_APP_API_URL;
export const ANALYTICS_API_URL = REACT_APP_OMT_ANALYTICS_API_URL;

export const COUNTRY_DATA = countryData;

export const PUBLIC_PATHS = [
  DOCUMENT_ROOT,
  "/sign-up",
  "/verify-email",
  "/reset-password",
  "/reset",
  "/ocean-data",
  "/category-view",
];

/* API ERRORS */
export const API_ERRORS = {
  400: "Oops! Something went wrong.\nPlease try again.",
  401: "You are unauthorized.\nPlease try logging in again.",
  403: "Forbidden access.\nPlease try logging in again.",
  404: "The resource you are trying to access cannot be found.\nPlease contact support.",
  500: "Oops! Something went wrong.\nPlease try again.",
};

/**
 * @const STORAGE_KEY
 */
export const STORAGE_KEY = {
  rememberMe: "rememberMe",
  access_token: "access_token",
  refresh_token: "refresh_token",
  user_info: "user_info",
};

/**
 * @constant FORM_VALIDATION
 */
export const FORM_VALIDATION = {
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  contactNumber: /^\d+$/,
  firstname: /\w{1,}/,
  lastname: /\w{1,}/,
  oldPassword: /\w{3,}/,
  password: /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
  confirmPassword:
    /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
  username:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  otp: /^\d{5}$/,
  passwordRule: {
    minLength: /.{8,}/,
    hasSymbols: /(?=.*[!@#$%^&*(),.?":{}|<>])/,
    hasBothCases: /(?=.*[a-z])(?=.*[A-Z])/,
    hasNumber: /(?=.*\d)/,
  },
  allSpace: /^[\s]+$/g,
  organisation: /\w{3,}/,
  datasetTitle: /\w{1,}/,
};

/**
 *
 */
export const POPUP_TYPES = {
  success: "success",
  error: "error",
  warning: "warning",
};

/**
 * @constant RESPONSIVE_BREAKPOINT
 */
export const RESPONSIVE_BREAKPOINT = {
  maxWidth: {
    sm: 575,
    md: 767,
    lg: 991,
    xl: 1199,
    xxl: 1399,
  },
  minWidth: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
};

/**
 * @constant SIDEBAR_TABS
 */
export const SIDEBAR_TABS = {
  myDataSet: "myDataSet",
  publicDatasets: "publicDatasets",
  oceanDatasets: "oceanDatasets",
};

/**
 * @constant VISIBILITY
 */
export const VISIBILITY = {
  PRIVATE: "private",
  PUBLIC: "public",
  OCEANOFDATA: "ocean-of-data",
  ORGANISATION: "organisation",
};

/**
 * @constant VISIBILITY_TYPE
 */
export const VISIBILITY_TYPE = [
  { label: "Private", value: "private" },
  { label: "Public", value: "public" },
  { label: "OceanOfData", value: "ocean-of-data" },
];

/**
 * @constant DATASET_ACCESS_TYPE
 */
export const DATASET_ACCESS_TYPE = [
  { label: "Admin", value: "ADMIN" },
  { label: "Editor", value: "EDITOR" },
  { label: "Viewer", value: "VIEWER" },
];

/**
 * @constant DATASET_STATUS
 */
export const DATASET_STATUS = {
  CREATED: "CREATED",
  PROCESSING: "PROCESSING",
  PROCESSED: "PROCESSED",
  PROCESSING_FAILED: "PROCESSING_FAILED",
  PUBLISHED: "PUBLISHED",
  UNPUBLISHED: "UNPUBLISHED",
  ARCHIVED: "ARCHIVED",
  DELETED: "DELETED",
};

/**
 * @constant MY_DATASET_RIGHT_PANEL_ACTIONS
 */
export const MY_DATASET_RIGHT_PANEL_ACTIONS = {
  OVERVIEW: "OVERVIEW",
  ACCESS: "ACCESS",
  STATS: "STATS",
};

/**
 * @constant DATASET_COLUMNS_DETAIL_VIEW_TYPE
 */
export const DATASET_COLUMNS_DETAIL_VIEW_TYPE = {
  SAMPLE: "SAMPLE",
  TYPE: "TYPE",
};

/**
 * @constant FILE_TYPES
 */
export const FILE_TYPES = [
  { label: "CSV", value: "csv", mimeType: "text/csv", format: ".csv" },
  { label: "TXT", value: "txt", mimeType: "text/plain", format: ".txt" },
  {
    label: "JSON",
    value: "json",
    mimeType: "application/json",
    format: ".json",
  },
  { label: "H5", value: "h5", mimeType: "text/plain", format: ".h5" },
  {
    label: "HDF5",
    value: "hdf5",
    mimeType: "application/x-hdf5",
    format: ".hdf5",
  },
  { label: "ASC", value: "asc", mimeType: "text/plain", format: ".asc" },
];

/**
 * @constant OCEAN_DATA_FILTERS
 */
export const OCEAN_DATA_FILTERS = [
  {
    lable: "Data Types",
    value: "data_type",
    options: [
      {
        title: "Imagery",
        value: "IMAGERY",
        description:
          "Satellite Images Underwater Photography Aerial Drone Footage",
      },
      {
        title: "Measurement Data",
        value: "MEASUREMENT_DATA",
        description:
          "Sensor Data (e.g., temperature, salinity, pH), Ocean Current Data, Acoustic Measurements",
      },
      {
        title: "Model Outputs",
        value: "MODEL_OUTPUTS",
        description:
          "Predictive Models (e.g., weather forecasts, ocean circulation models), Simulations (e.g., wave or tsunami simulations)",
      },
      {
        title: "Textual Data",
        value: "TEXTUAL_DATA",
        description:
          "Plastic Pollution Data Oil Spill Tracking Water Quality Indicators",
      },
      {
        title: "Geospatial Data",
        value: "GEOSPATIAL_DATA",
        description: "GIS Layers,Bathymetric Maps, Shape-Files",
      },
    ],
  },
  {
    lable: "Data Source",
    value: "data_source",
    options: [
      {
        title: "Satellite Data",
        value: "SATELLITE_DATA",
        description: "Data from ocean-observing satellites (e.g., NASA, ESA).",
      },
      {
        title: "In-Situ Data",
        value: "IN-SUIT_DATA",
        description:
          "Buoy Data,Ship-Based Measurements Underwater Drones (AUVs) and ROVs",
      },
      {
        title: "Crowdsourced Data",
        value: "CROWDSOURCED_DATA",
        description:
          "Data from citizen science projects Public platforms (e.g., sightings of marine species)",
      },
      {
        title: "Institutional Data",
        value: "INSTITUTIONAL_DATA",
        description:
          "Government Agencies (e.g., NOAA, USGS) Research Institutions (e.g., Woods Hole, Scripps) Non-GOV Organizations (NGOs)",
      },
    ],
  },
  {
    lable: "Thematic Areas",
    value: "thematic_areas",
    options: [
      {
        title: "Climate Change",
        value: "CLIMATE_CHANGES",
        description:
          "Sea Level Rise Data Ocean Temperature Changes Ocean Acidification",
      },
      {
        title: "Marine Biology",
        value: "MARINE_BIOLOGY",
        description:
          "Species Distribution Ecosystem Health Coral Reef Monitoring",
      },
      {
        title: "Fisheries",
        value: "FISHERIES",
        description:
          "Fish Stock Assessments Fishing Activity Logs Sustainable Fisheries Data",
      },
      {
        title: "Pollution",
        value: "POLLUTION",
        description:
          "Plastic Pollution Data Oil Spill Tracking Water Quality Indicators",
      },
      {
        title: "Geology",
        value: "GEOLOGY",
        description: "Seafloor Mapping, Sediment Data, Tectonic Activity",
      },
    ],
  },
  {
    lable: "License",
    value: "license",
    options: [
      {
        title: "Open Access",
        value: "POEN_ACCESS",
        description: "It is free dataset, everyone can use this.",
      },
      {
        title: "Non-Commercial",
        value: "NON_COMMERCIAL",
        description: "You have to pay for this dataset.",
      },
      {
        title: "Attribution",
        value: "ATTRIBUTION",
        description: "It is free dataset, everyone can use this.",
      },
      {
        title: "Share-Alike",
        value: "SHARE_ALIKE",
        description: "You have to pay for this dataset.",
      },
      {
        title: "Government",
        value: "GOVERNMENT",
        description: "You have to pay for this dataset.",
      },
      {
        title: "Restricted Access",
        value: "RESTRICTED_ACCESS",
        description: "You have to pay for this dataset.",
      },
    ],
  },
];
/**
 * @constant SELECTION_VALIDATION
 */

export const SELECTION_VALIDATION = [
  { value: "csv", mimeType: "text/csv", format: ".csv" },
  { value: "txt", mimeType: "text/plain", format: ".txt" },
  { value: "json", mimeType: "application/json", format: ".json" },
  { value: "h5", mimeType: "text/plain", format: ".h5, hdf5" },
];

export const SEARCH_FILTER_TYPES = {
  tags: "tags",
  styles: "styles",
  types: "types",
  collections: "collections",
};

/**
 * @constant COMPOSE_SEARCH_FILTERS
 */
export const COMPOSE_SEARCH_FILTERS = {
  tags: "",
  styles: "",
  types: "",
  collections: "",
};

export const MODEL_STYLE = {
  composition: "composition",
  dialogue: "dialogue",
};

/**
 * @constant DATASET_OBJECT
 */
export const DATASET_OBJECT = {
  uid: "",
  name: "",
  description: "",
  Creator_id: "",
  creator_name: "",
  creation_date: null,
  dataset_format: "",
  last_modified_date: null,
  published_date: null,
  status: "",
  s3_url: "",
  tags: [],
  visibility: "",
  latest_version: {
    version: "",
    uid: "",
    name: "",
    version_alias: "",
    description: "",
    creator_name: "",
    creation_date: null,
    last_modified_date: null,
    published_date: null,
    status: "",
    dataset_format: "",
    s3_url: "",
    tags: [],
    visibility: "",
    formats: [],
    dataset_format: "",
    dataset_size: "",
    dataset_preview_url: "",
    dataset_preview_format: "",
    dataset_schema: "",
    dataset_records_count: "",
    dataset_columns_count: "",
  },
};

/**
 * @constant ROOT_DATASET_API_RESPONSE_OBJ
 */
export const ROOT_DATASET_API_RESPONSE_OBJ = {
  has_next: false,
  has_previous: false,
  page: 1,
  page_size: 10,
  results: [],
  total_results: 0,
};

/**
 * @constant DEFAULT_MODEL_SETTINGS
 */
export const DEFAULT_MODEL_SETTINGS = {
  contextTextboxValue: "",
  chatboxInputText: "",
  impersonationSelected: "",
  creativity: 0,
  isDefaultCreativitySet: false,
  topWords: 0,
  isDefaultTopWordsSet: false,
  maxWords: 0,
  isDefaultMaxWordsSet: false,
};

/**
 * @constant GENERATE_BOX_DEFAULT_FONTSIZE
 */
export const GENERATE_BOX_DEFAULT_FONTSIZE = 14;

export const TOOLTIP_CONFIG = {
  dummyText:
    "Lorem ipsum text goes here. Some more text can be added after this.",
  content: {
    title:
      "Title is a piece of short and sweet information about your content. The title is the first part where your audience sees before the content. Here the viewer decides whether to watch the content or not.",
    contentType:
      "The type of content (Image, Video, Audio or Book) you want to upload",
    contentFile:
      "The maximum allowed size to upload is 1GB. Beyond that limit you cannot proceed to upload.",
    keywords:
      "Keywords are descriptive words you can add to your content to help viewers find your content.",
    description: `The title, thumbnail, and description are more important pieces of metadata for your content's discovery. These main pieces of information help viewers decide what content to watch.`,
    comments:
      "Lorem ipsum text goes here. Some more text can be added after this.",
  },
};

/**
 * @constant DOWNLOAD_PREDICTED_DATA
 */
export const DOWNLOAD_PREDICTED_DATA = {
  format: function (modelName, text, isContext = false) {
    return (
      "-- DeepSage -- \n \n" +
      `Model - ${modelName}` +
      "\n \n" +
      `${isContext ? "Context" : "Generated Text"} - ${text}`
    );
  },
  formatContextComposition: function (modelName, text, context) {
    return (
      "-- DeepSage -- \n \n" +
      `Model - ${modelName}` +
      "\n \n" +
      `Context - ${context}` +
      "\n \n" +
      `Generated Text - ${text}`
    );
  },
};
