import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DOCUMENT_ROOT } from '../../config';
import '../../styles/modalComponent.scss';
import { getVersionString } from '../../utils/utils';
import HgButtonComponent from './hgButtonComponent';

function DatasetVersionsComponent(props) {
    const { data, toggle,  modal, onAccept} = props
    const [selectedDatasetVersion, setSelectedDatasetVersion] = useState({uid: null});
    const history = useHistory()

    const addDatasetVersion = () => {
        history.push(`${DOCUMENT_ROOT}add-dataset-version/${data[0].root_dataset_id}`)
    }

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>
                Click Me
            </Button> */}
            <Modal isOpen={modal} toggle={toggle} size={"md"} {...props}>
                <ModalHeader toggle={toggle}>Select Dataset Version</ModalHeader>
                <ModalBody>
                    <ul className='versions-list'>
                        {data.map(item => {
                            return <li key={item.uid}
                                onClick={() => setSelectedDatasetVersion(item)}
                                className={selectedDatasetVersion.uid === item.uid ? "active" : ""}>
                                {getVersionString(item)}
                            </li>
                        })}
                    </ul>
                </ModalBody>
                <ModalFooter>
                    <HgButtonComponent buttonText={"Add Version"} isPrimary={true} onclickHandler={addDatasetVersion} />
                    <HgButtonComponent buttonText={"Accept"} isPrimary={false} onclickHandler={() => onAccept(selectedDatasetVersion)} />
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default DatasetVersionsComponent;