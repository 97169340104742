import React from 'react';
import '../../styles/hgButtonComponent.scss';

const HgButtonComponent = (props) => {
    const { buttonText, onclickHandler, isPrimary, disabled, buttonType } = props;
    return (
        <div className="hg-button">
            {isPrimary
                ? <button className="btn btn-primary" onClick={onclickHandler} disabled={disabled ? true : false} type={buttonType === 'submit' ? 'submit' : 'button'}>{buttonText}</button>
                : <button className="btn btn-secondary" onClick={onclickHandler} disabled={disabled ? true : false} type={buttonType === 'submit' ? 'submit' : 'button'}>{buttonText}</button>
            }

        </div>
    )
}

export default HgButtonComponent;