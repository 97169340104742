import { FORM_VALIDATION } from "../config";

export class FormUtils {

    static validateFormField (fieldName, value, valueToCompare = '') {
        let fieldValid = true;
        let errorMessage = '';
        let comparedValueValid = true;
        switch (fieldName) {
            case 'firstname':
                fieldValid = value.length === 0 || FORM_VALIDATION.firstname.test(value);
                errorMessage = 'Please enter your first name';
                break;
            case 'lastname':
                fieldValid = value.length === 0 || FORM_VALIDATION.lastname.test(value);
                errorMessage = 'Please enter your last name';
                break;
            case 'username':
                fieldValid = value.length === 0 || FORM_VALIDATION.username.test(value);
                errorMessage = 'Enter a valid email address';
                break;
            case 'email':
                fieldValid = value.length === 0 || FORM_VALIDATION.email.test(value);
                errorMessage = 'Enter a valid email address';
            case 'oldPassword':
                fieldValid = value.length === 0 || FORM_VALIDATION.oldPassword.test(value);
                errorMessage = 'Enter your current password';
                break;
            case 'password':
                fieldValid = FORM_VALIDATION.password.test(value);
                comparedValueValid = ((valueToCompare && fieldValid && value === valueToCompare) || valueToCompare === '');
                if (!fieldValid) {
                    errorMessage = value !== '' ? 'Password should have atleast 8 characters which includes Capital letter, Small letter, Symbol & number' : 'Enter your password';
                }
                break;
            case 'confirmPassword':
                fieldValid = FORM_VALIDATION.confirmPassword.test(value) && (value === valueToCompare);
                if (!fieldValid) {
                    errorMessage = value !== '' ? (value !== valueToCompare ? 'Enter the same password' : 'Enter valid password') : 'Enter your password';
                }
                break;
            // case 'organisation':
            //     fieldValid = value.length === 0 || FORM_VALIDATION.organisation.test(value);
            //     errorMessage = 'Please enter your organisation';
            default:
                break;
        }

        return {fieldValid, errorMessage, comparedValueValid};
    }
}