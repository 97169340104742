import React, { Component } from 'react';
import '../styles/loginComponent.scss';
import '../styles/commonStyle.scss';
import '../styles/form.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as accountActions from '../actions/accountActions';
import { DOCUMENT_ROOT, FORM_VALIDATION, STORAGE_KEY } from '../config';
import FormValidationErrorContainer from './pure/formValidationErrorContainer';
import { ImgGoogle, ImgGooglePlus, ImgLoginBgEclipse, ImgNlg, ImgOMTLogo, ImgWelcomeTBBC, Logo } from '../images';
import Loader from './loaderComponent';
import { localStorageSetItem } from '../utils/localStorageUtils';
import { FormUtils } from '../utils/formUtils';

class LoginSuccessComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEmailVerified: false
        }
    }

    componentDidMount() {
        const { key } = this.props.match.params;
        if (key) {
            this.props.actions.verifyEmail(key);
        }
        
        // if (key) {
        //     const emailVerificationKey = key.split("key=").pop();
        //     this.props.actions.verifyEmail(emailVerificationKey);
        // }
    }

    clickHereHandler = () => {
        this.props.history.push(DOCUMENT_ROOT);
    }

    render() {
        return (
            <div className="login-component">
                {/* <header className="header">
                    <Link to={'/'}><img src={Logo} /></Link>
                </header> */}
                <main className="main">
                <>
                            <div style={{marginBottom: '24px'}}><img src={ImgOMTLogo}/></div>
                            <div className="section-heading">User Logged In successfully!</div>
                            {/* <div className="helper-text">To log out <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={this.clickHereHandler}>Click here.</span></div> */}
                        </>
                </main>
                <img src={ImgWelcomeTBBC} className="welcome-tbbc" />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.account.isVerifyEmailLoading,
        isVerifyEmailSuccess: state.account.isVerifyEmailSuccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(accountActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginSuccessComponent);