import React from 'react';
import '../../styles/addDatasetCopy.scss'
import closeIcon from '../../images/close.svg' 
import closeIcon1 from '../../images/close-icon.png'
import { useHistory } from "react-router-dom";

const HeadingComponent = (props) => {
    const history = useHistory();

      // Log props to debug
      console.log("Received props:", props);

      const handleClose = () => {
        // Call the passed handleClose function from the parent
        props.handleClose();
        
      };

    return (
        <div className="frame">
            <div className="ADD-DATASETS">Add Dataset</div>
            <img 
                className="close" 
                alt="Close" 
                src={closeIcon1} 
                style={{cursor:'pointer'}}
                onClick={handleClose} // Correct onClick syntax
            />
        </div>
    );
}

export default HeadingComponent;