import React, { useState } from 'react'
import '../../styles/manageAccess.scss'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { ImgSearch } from '../../images';
import { IoIosCloseCircleOutline } from "react-icons/io";
import Switch from '@mui/material/Switch';
import EditAccessPopupComponent from './editAccessPopupComponent';
import HgButtonComponent from './hgButtonComponent';

const ManageAccessPopupComponent = (props) => {
  const { toggle, modal, sharedUsers } = props
  const [editAccess, setEditAccess] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const handleEditAccess = (user) => {
    setSelectedUser(user);
    toggle(); 
    setEditAccess(true);
  };

  const handleCancelEditAccess = () => {
    setEditAccess(false); 
    toggle(); 
  };

  return (
    <div className='manage-access-component'>
    <Modal isOpen={modal} toggle={toggle} size={"md"} className='manage-access-component' {...props}>
      <ModalHeader toggle={toggle} style={{fontSize: '24px'}}>Manage Access</ModalHeader>
      <ModalBody>
        <div className='sub-heading'>Following datasets are processing in the background. Please do not close or exit your browser window to ensure the upload completes successfully.</div>
        <Button className='upload-btn'>Upload New Version</Button>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search"
            className="search-input"
          />
          <div className='icon-container'>
          <img className='search-icon' src={ImgSearch}/>
          </div>
        </div>
        <div className='user-list'>
          {sharedUsers.map((item)=> (
            <div key={item.uid} className='user-row'>
              <span className="user-name">{`${item.first_name} ${item.last_name}`}</span>
              <span className='user-role'>{item.access_type.charAt(0).toUpperCase() + item.access_type.slice(1).toLowerCase()}</span>
              <span className="edit-access" onClick={() => handleEditAccess(item)}>Edit Access</span>
              <span className="remove-user"><IoIosCloseCircleOutline size={24} /></span>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className='footer'>
          <Switch/>
          <label>Apply changes to all versions</label>
          <HgButtonComponent buttonText={"Cancel"} isPrimary={false} onclickHandler={toggle} />
          <HgButtonComponent buttonText={"Save"} isPrimary={true} type="submit"/>
        </div>
      </ModalFooter>
    </Modal>
    <EditAccessPopupComponent
      toggle={handleCancelEditAccess}
      modal={editAccess}
      user={selectedUser} 
    />
    </div>
  )
}

export default ManageAccessPopupComponent