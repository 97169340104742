import React from "react";
import "../../styles/pillButton.scss";
import { ImgCross } from "../../images";
const PillButton = ({ keywordObj, removeKeyword }) => {
  return (
    <div className="pill-container">
      {keywordObj.Term && keywordObj.Category && keywordObj.Topic
        ? `${
            keywordObj?.Variable_Level_1 && keywordObj?.Variable_Level_1 + " : "
          } ${keywordObj?.Term && keywordObj?.Term + " : "} ${
            keywordObj?.Topic && keywordObj?.Topic + " : "
          } ${keywordObj?.Category}`
        : keywordObj.Variable_Level_1}

      <img onClick={removeKeyword} src={ImgCross} alt="delete" width={14} />
    </div>
  );
};

export default PillButton;
