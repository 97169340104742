export function localStorageSetItem(key, value) {
  if (typeof(Storage) !== "undefined") {
    localStorage.setItem(key, value);
  } else {
    console.log('No localStorage support');
  }
}

export function localStorageGetItem(key) {

  if (typeof(Storage) !== "undefined") {
    return localStorage.getItem(key);
  } else {
    console.log('No localStorage support');
  }
}

export function localStorageSetObject(key, value) {
  if (typeof(Storage) !== "undefined") {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    console.log('No localStorage support');
  }
}

export function localStorageGetObject(key) {
  if (typeof(Storage) !== "undefined") {
    let value = localStorage.getItem(key);
    return (value !== "undefined" ? JSON.parse(value) : null);
  } else {
    console.log('No localStorage support');
  }
}

export function localStorageRemoveItem(key) {
  if (typeof(Storage) !== "undefined") {
    localStorage.removeItem(key);
  } else {
    console.log('No localStorage support');
  }
}

export function localStorageClear(key) {
  if (typeof(Storage) !== "undefined") {
    localStorage.clear();
  } else {
    console.log('No localStorage support');
  }
}
