import React, { useRef, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import '../../styles/modalComponent.scss';
import '../../styles/shareDatasetComponent.scss';
import '../../styles/form.scss';
import HgButtonComponent from './hgButtonComponent';
import { DATASET_ACCESS_TYPE } from '../../config';
import HgDropdownComponent from './hgDropdownComponent';
import { ImgAddBalck, ImgPlus } from '../../images';

function ShareDatasetComponent(props) {
    const { rootDatasetId, toggle, modal, onAccept, onSubmit } = props;
    const formObject = {email: '', accessType: '', isValid: true};
    const [formElementsList, setFormElementsList] = useState([formObject]);
    const formRef = useRef()

    const onBlur = (e) => {

    }

    const onChange = (index, event) => {
        let data = [...formElementsList];
        data[index][event.target.name] = event.target.value;
        setFormElementsList(data);

    }

    const onAccessTypeSelect = (item, index) => {
        let data = [...formElementsList];
        data[index].accessType = item.value;
        setFormElementsList(data);
        
    }

    const fromElement = (input, index) => {
        return (
            <>
                <div className="form-field">
                    <div className='form-label'>Share to</div>
                    <input type="text"
                        name="email"
                        className="form-control"
                        placeholder="receipient email"
                        onBlur={(event) => onBlur(index, event)}
                        onChange={(event) => onChange(index, event)}
                        value={input.email} 
                        />
                    {/* <span className="form-icon email"></span> */}
                    {/* {!this.state.usernameValid && <FormValidationErrorContainer errMessage={this.state.usernameErrorMessage} />} */}
                </div>
                <div className="form-field drop-down">
                    <div className='form-label'>Access Type</div>
                    <HgDropdownComponent
                        listItems={DATASET_ACCESS_TYPE}
                        placeholder={"Select"}
                        onSelectHandler={(item) => onAccessTypeSelect(item, index)}
                    />
                </div>
            </>
        )
        }

    const onAdditionalUsersHandler = () => {
        setFormElementsList([...formElementsList, formObject])
    }

    const validateFormData = () => {
        const data = [...formElementsList];

        onSubmit(rootDatasetId, data.map(item => {
            return {
                root_dataset_id: rootDatasetId,
                email: item.email,
                access_type: item.accessType,
                is_downloadable: true,
                is_paid: false
            }
        }));

    }


    return (
        <div className='share-dataset-component'>
            <Modal isOpen={modal} toggle={toggle} size={"md"} className={"share-dataset-component"} {...props}>
                <ModalHeader toggle={toggle}>Share Dataset</ModalHeader>
                <ModalBody>
                    <div className='gen-info'>
                        Sharing dataset or adding user to the dataset is given access rights to the user.
                    </div>
                    <form className="dsg-form" noValidate={false} ref={formRef}>
                        {formElementsList.map((item, index) => {
                            return <fieldset key={index}>{fromElement(item, index)}</fieldset>
                            })}
                    </form>
                    <div className='additional-users'>
                        Additional Users <img src={ImgAddBalck} onClick={onAdditionalUsersHandler} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <HgButtonComponent buttonText={"Cancel"} isPrimary={false} onclickHandler={() => onAccept()} />
                    <HgButtonComponent buttonText={"Share"} isPrimary={true} onclickHandler={validateFormData} type="submit" />
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ShareDatasetComponent;