import { API_URL, DATASET_STATUS, VISIBILITY } from "../config";
import axiosInstance from "./axiosConfig";
import axios from "axios";

let CancelToken = axios.CancelToken;
let source = CancelToken.source();

export default class DatasetApi {
  static getrootDatasets(
    view_page = VISIBILITY.PRIVATE,
    filters = {},
    page = 1
  ) {
    source.cancel();
    source = CancelToken.source();
    const params = {
      view_page,
      page,
    };
    const request_urls = [];
    request_urls.push(
      axiosInstance.get(`${API_URL}/auth/v1/get-root-dataset-list/`, {
        params: params,
        cancelToken: source.token,
      })
    );
    return axios.all(request_urls, { params: params });
  }

  // get-root-dataset-list-filter/
  static getFilteredDataset(
    view_page = VISIBILITY.PRIVATE,
    filters = {},
    page
  ) {
    const data = axiosInstance.get(
      `${API_URL}/auth/get-root-dataset-list-filter/`,
      {
        params: {
          view_page,
          ...filters,
          page,
        },
      }
    );
    return data;
  }

  static getSharedDatasets(visibility = VISIBILITY.PRIVATE, filters = {}) {
    // source.cancel();
    // source = CancelToken.source();
    const params = {
      visibility,
      sorting_field: "creation_date",
      sorting_order: "desc",
      shared: true,
      ...filters,
    };
    return axiosInstance.get(`${API_URL}/auth/get-root-dataset-list/`, {
      params: params,
    });
  }

  static getRootDatasetInfo(root_dataset_id) {
    // source.cancel();
    // source = CancelToken.source();
    const params = {
      root_dataset_id,
    };
    return axiosInstance.get(`${API_URL}/auth/v1/get-root-dataset/`, {
      params: params,
      cancelToken: source.token,
    });
  }

  static getOceanDataset(view_page = VISIBILITY.OCEANOFDATA, page = 1) {
    const params = {
      view_page,
      page,
    };
    const request_urls = [];
    request_urls.push(
      axiosInstance.get(`${API_URL}/auth/v1/all-dataset/`, {
        params: params,
      })
    );
    return axios.all(request_urls, { params: params });
  }

  /**
   * @param {object} filterObj
   * @param {string} view_page
   * @returns {object}
   */

  static getOceanFilterDataset(view_page, filterObj) {
    const params = { view_page, ...filterObj };
    return axios.get(`${API_URL}/auth/v1/all-dataset-filter/`, {
      params: params,
    });
  }
  /**
   *
   * @param {string} fileFormat
   * @returns Object
   */
  static getPresignedUrlToUpload(fileFormat) {
    source.cancel();
    source = CancelToken.source();
    const requestBody = {
      file_format: fileFormat,
    };
    return axiosInstance.post(`${API_URL}/auth/presigned-url/`, requestBody, {
      cancelToken: source.token,
    });
  }

  /**
   * Add Dataset API
   * @param {string} name
   * @param {string} s3_url
   * @param {string} visibility
   * @param {string} dataset_format
   * @param {string} description
   * @param {Array<string>} tags
   * @returns
   */
  static addDataset(
    name,
    description = "",
    s3_url,
    prj_s3_url,
    visibility,
    dataset_format,
    attribution = "",
    website = "",
    latitude,
    longitude,
    license_slug = "",
    version_choice = "",
    version = "",
    restrict_share_public,
    restrict_share_org,
    license_url
  ) {
    source.cancel();
    source = CancelToken.source();
    const requestBody = {
      name,
      description,
      s3_url,
      prj_s3_url,
      visibility,
      dataset_format,

      attribution,
      website,
      latitude,
      longitude,
      license_slug,

      version_choice,
      version,
      restrict_share_public,
      restrict_share_org,
      license_url,
    };
    return axiosInstance.post(
      `${API_URL}/auth/v1/add-root-dataset-meta-data/`,
      requestBody,
      { cancelToken: source.token }
    );
  }

  static updateRootDatasetInfo(
    name,
    visibility,
    description = "",
    tags = [],
    rootdatasetId
  ) {
    source.cancel();
    source = CancelToken.source();
    const requestBody = {
      name,
      visibility,
      description,
      tags,
      rootdataset_id: rootdatasetId,
    };
    return axiosInstance.post(
      `${API_URL}/auth/v1/update-root-dataset-metadata/`,
      requestBody,
      { cancelToken: source.token }
    );
  }

  static updateDatasetStatus(dataset_ids, status) {
    if (typeof dataset_ids === "string") {
      dataset_ids = [dataset_ids];
    }
    const requestBody = {
      dataset_ids,
      status,
    };
    return axiosInstance.post(
      `${API_URL}/auth/update-root-dataset-status/`,
      requestBody
    );
  }

  static shareDataset(data = []) {
    source.cancel();
    source = CancelToken.source();

    console.log("Data from shareDataset api:: inside datasetApi", data);
    const requestBody = data;
    return axiosInstance.post(
      `${API_URL}/auth/share-root-dataset/`,
      requestBody,
      { cancelToken: source.token }
    );
  }

  static getDatasetVerionsList(rootdataset_id, visibility, status) {
    // source.cancel();
    // source = CancelToken.source();
    const params = {
      rootdataset_id,
      visibility,
      status,
    };
    return axiosInstance.get(`${API_URL}/auth/get-child-dataset-list/`, {
      params: params,
      cancelToken: source.token,
    });
  }

  static getDatasetVersionInfo(dataset_id) {
    source.cancel();
    source = CancelToken.source();
    const params = {
      dataset_id,
    };
    return axiosInstance.get(`${API_URL}/auth/get-version-dataset/`, {
      params: params,
      cancelToken: source.token,
    });
  }

  static deleteBulkDataset(dataset_ids) {
    const requestBody = {
      status: DATASET_STATUS.DELETED,
      dataset_ids,
    };
    return axiosInstance.post(
      `${API_URL}/auth/update-dataset-version-status/`,
      requestBody
    );
  }

  static downloadBulkDataset(dataset_ids) {
    const requestBody = {
      child_dataset_ids: dataset_ids,
    };
    return axiosInstance.post(
      `${API_URL}/auth/get-child-dataset-s3url/`,
      requestBody
    );
  }

  static downloadDataset(datasets = []) {
    console.log("Datasets in dataset api::", datasets);
    source.cancel();
    source = CancelToken.source();
    const requestBody = {
      datasets,
    };
    return axiosInstance.post(
      `${API_URL}/auth/get-presigned_url-to-download-dataset/`,
      requestBody
    );
  }

  static getDatasetSharedUsers(dataset_id) {
    const params = {
      dataset_id,
    };
    return axiosInstance.get(`${API_URL}/auth/get-users-with-shared-dataset/`, {
      params: params,
      cancelToken: source.token,
    });
  }

  static datasetShareEnableDisble(user_id, dataset_id, is_enabled) {
    const params = {
      user_id,
      dataset_id,
      is_enabled,
    };
    return axiosInstance.get(
      `${API_URL}/auth/update-isenable-shared-with-rel/`,
      { params: params, cancelToken: source.token }
    );
  }

  static addDatasetVersion(
    root_dataset_id,
    s3_url,
    visibility,
    dataset_format,
    tags = [],
    comment = "",
    version_alias = ""
  ) {
    source.cancel();
    source = CancelToken.source();
    const requestBody = {
      root_dataset_id,
      s3_url,
      visibility,
      dataset_format,
      comment,
      tags,
      version_alias,
    };
    return axiosInstance.post(
      `${API_URL}/auth/add-dataset-version-metadata/`,
      requestBody,
      { cancelToken: source.token }
    );
  }

  /**
   * Cancel API call
   */
  static cancelApiCall() {
    source.cancel();
    source = CancelToken.source();
  }
}
