import React from 'react'
import '../../styles/listViewDatasetComponent.scss'
import { colors } from './gridViewDatasetComponent'

const ListViewDatasetComponent = ({title, description, payment, data_type}) => {
    const datasetBackgroundColor = colors[data_type] || colors.IMAGERY;
    return (
        <div className='list-dataset-container'>
            <div className='dataset-details'>
                <div className='dataset-img' style={{ background: datasetBackgroundColor }}></div>
                <div className='dataset-info'>
                    <div className='dataset-title'>{title || "Tsunami Simulation Data"}</div>
                    <div className='organisation'>NASA</div>
                </div>
            </div>
            <div className='dataset-description'>{description || "Simulation data for tsunami impact predictions."}</div>
            <div className='data-type'>{data_type || "GeoTiff"}</div>
        <button className='btn'>{payment || "Free"}</button>
        </div>
    )
}

export default ListViewDatasetComponent