import * as config from '../config';
import React from 'react';
import { Link } from 'react-router-dom';
import SessionUtils from './sessionUtils';
import _ from 'lodash';

export function capitalizeFirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function uncapitalizeFirst(str) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function truncateText(str, length = '50', ending = '...') {
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

export function generateUniqueID() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
}

export function formatCurrency(price, currencyCode) {
  switch (currencyCode) {
    case 'GBP':
      return '&pound;' + price;
      break;
    case 'INR':
      return '&#8377;' + price;
      break;
    case 'USD':
      return '$' + price;
      break;
    default:
      return currencyCode + ' ' + price;
      break;
  }
}

export function mapRelationships(childRelationship, parentGender, childGender) {
  const relationships = {
    'son': {
      'male': 'father',
      'female': 'mother'
    },
    'daughter': {
      'male': 'father',
      'female': 'mother'
    }
  }

  return relationships[childRelationship][parentGender];
}

export function formatErrorMessages(message, username) {
  if (message === 'User already activated! To login, <click here>.') {
    return (
      <span className="smartErrors">
        User already activated! To login, <Link to={config.DOCUMENT_ROOT + 'login'}>tap here</Link>.
      </span>
    )
  }
  if (message === 'User not activated! To activate account, <click here>.') {
    return (
      <span className="smartErrors">
        User not activated! To activate account, <Link to={config.DOCUMENT_ROOT + 'activate-account/' + username}>Click here</Link>.
      </span>
    )
  }
  if (message === 'User already registered! To login, <click here>.') {
    return (
      <span className="smartErrors">
        User already registered! To login, <Link to={config.DOCUMENT_ROOT + 'login'}>tap here</Link>.
      </span>
    )
  }
  if (message === 'User not registered! To sign up, <click here>.') {
    return (
      <span className="smartErrors">
        User not registered! To sign up, <Link to={config.DOCUMENT_ROOT + 'sign-up'}>tap here</Link>.
      </span>
    )
  }
  return message;
}

export const formatVirtualSerialId = (virtualSerialId) => {
  let stringProcessing = virtualSerialId.split('-');
  let formattedVirtualSerialId = stringProcessing[1] ?
    (stringProcessing[0].toUpperCase() + '-' + stringProcessing[1].toLowerCase()) : virtualSerialId;
  return formattedVirtualSerialId;
}

export const getGeniusName = (profileId) => {
  try {
    const { childProfiles } = SessionUtils.getDomain();
    const childProfile = _.find(childProfiles, { profileId });
    const [geniusName] = childProfile.name.split(' ');
    return geniusName;
  } catch (e) {
    console.log("Exception occured: ", e);
    console.log("Error while parsing name");
    return 'Child';
  }
  
}
