import React from "react";
import { ExploreDownArrow } from "../images";

const ShowMoreButton = ({toggle,content,bgColor}) => {
  return (
    <button
      style={{
        borderRadius: " 40px",
        backgroundColor: bgColor?bgColor:"whitesmoke",
        border: "none",
        fontFamily: "Space Mono",
        fontSize: "11px",
        fontWeight: "400",
        lineHeight: "20px",
        textAlign: "center",
        height:"auto",
        padding:"0.5em 1em"
      }}
      onClick={toggle}
    >
      {content}{content === "Show More"?<img src={ExploreDownArrow}/>:""}
    </button>
  );
};

export default ShowMoreButton;
