import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../../styles/modalComponent.scss';
import { getVersionString } from '../../utils/utils';
import DatasetPreviewComponent from './datasetPreviewComponent';
import HgButtonComponent from './hgButtonComponent';

function DatasetPreviewPopupComponent(props) {
    const { toggle,  modal, onAccept, preview_table_header, preview_table_data} = props

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>
                Click Me
            </Button> */}
            <Modal isOpen={modal} toggle={toggle} size={"xl"} scrollable={true} {...props}>
                <ModalHeader toggle={toggle}>Dataset Preview</ModalHeader>
                <ModalBody>
                    <DatasetPreviewComponent preview_table_header={preview_table_header} preview_table_data={preview_table_data} />
                </ModalBody>
                <ModalFooter><HgButtonComponent buttonText={"Close"} isPrimary={false} onclickHandler={() => onAccept()} /></ModalFooter>
            </Modal>
        </div>
    );
}

export default DatasetPreviewPopupComponent;