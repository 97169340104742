import React, { Component } from 'react';
import { Spinner } from 'reactstrap';
import { ImgDeepsageSpinner, ImgOMTLoader } from '../images';
import '../styles/loaderComponent.scss';

export class Loader extends Component {
  render() {
    return (
      <div className={'lds-spinner ' + (this.props.customClass ? this.props.customClass : '')}>
        <Spinner color="primary">
          Loading...
        </Spinner>
      </div>
      // <div className={'lds-spinner ' + (this.props.customClass ? this.props.customClass : '')}>
      //   <img src={ImgOMTLoader} />
      // </div>
    );
  }
}

export default Loader;
