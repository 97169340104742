import React, { useEffect, useRef, useState } from 'react';
import '../../styles/datasetPreviewComponent.scss';


const DatasetPreviewComponent = (props) => {
    const { preview_table_header, preview_table_data } = props;
    const [columnsLengthMap, setColumnsLengthMap] = useState({});
    const previewTableRef = useRef();

    
    const maxColumnWidth = 600;


    useEffect(() => {
        const columnMap = {}
        if (preview_table_data.length && preview_table_header.length) {
            for (let item of preview_table_data) {
                for (let key of preview_table_header) {
                    columnMap[key] = key.length;
                    try {
                        if (key in columnMap) {
                            if (columnMap[key] < item[key].toString().length) {

                                if (item[key].toString().length > maxColumnWidth) {
                                    columnMap[key] = maxColumnWidth;
                                } else {
                                    columnMap[key] = item[key].toString().length;
                                }
                            }
                        } else {
                            columnMap[key] = item[key].toString().length;
                        }
                    } catch {
                        columnMap[key] = item[key].toString().length;
                    }
                    if (columnMap[`${key}DataType`]) {
                        columnMap[`${key}DataType`] = [...columnMap[`${key}DataType`], typeof item[key]];
                        columnMap[`${key}DataTypeCount`] = columnMap[`${key}DataType`].reduce(function (value, value2) {
                            return (
                                value[value2] ? ++value[value2] :(value[value2] = 1),
                                value
                            );
                        }, {});
                        columnMap[`${key}DataTypeCountMax`] = Object.keys(columnMap[`${key}DataTypeCount`]).reduce((a, b) => columnMap[`${key}DataTypeCount`][a] > columnMap[`${key}DataTypeCount`][b] ? a : b);
                    } else {
                        columnMap[`${key}DataType`] = [typeof item[key]];
                        columnMap[`${key}DataTypeCount`] = columnMap[`${key}DataType`].reduce(function (value, value2) {
                            return (
                                value[value2] ? ++value[value2] :(value[value2] = 1),
                                value
                            );
                        }, {});
                        columnMap[`${key}DataTypeCountMax`] = Object.keys(columnMap[`${key}DataTypeCount`]).reduce((a, b) => columnMap[`${key}DataTypeCount`][a] > columnMap[`${key}DataTypeCount`][b] ? a : b);
                    }
                }
            }
            setColumnsLengthMap(columnMap);
        }
    }, [preview_table_data, preview_table_header])



    return (
        <div className='preview-table' ref={previewTableRef}>
            {preview_table_header.length > 0 && <>
                <ul className='table-header'>
                    {preview_table_header.map(item => {
                        return <li key={item} className='header-item' style={{ justifyContent: (`${columnsLengthMap[item + "DataTypeCountMax"]}` === 'number') ? 'center' : 'flex-start', width: `${(columnsLengthMap[item] * 10) + 20}px` }}>{item}</li>
                    })}
                </ul>
                <ul className='table-data'>
                    {preview_table_data.map((item, index) => {
                        return (<ul key={index}>
                            {preview_table_header.map((key, index) => {
                                return <li key={`${item[key]}${index}`} style={{ justifyContent: (`${columnsLengthMap[key + "DataTypeCountMax"]}` === 'number') ? 'center' : 'flex-start', width: `${(columnsLengthMap[key] * 10) + 20}px` }}>{item[key]}</li>
                            })}
                        </ul>)
                    })
                    }
                </ul>
            </>}
        </div>
    )
}

export default DatasetPreviewComponent;