import React, { useState } from "react";
import "../../styles/selectComponent.scss";
import { RxCross1 } from "react-icons/rx";
import { GoQuestion } from "react-icons/go";
import { OCEAN_DATA_FILTERS } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import {
  getOceanFilterDataset,
  setOceanDataFilter,
} from "../../actions/datasetActions";

const SelectComponent = ({ setIsFilter }) => {
  // const [filterObj, setFilterObj] = useState({});
  const dispatch = useDispatch();
  const { filterObj } = useSelector((state) => ({
    filterObj: state.dataset.oceanDataFilter,
  }));

  const handleFilters = (e, lable) => {
    if (e.target.checked) {
      // creating key and adding the value to it
      if (!filterObj[lable]) {
        dispatch(
          setOceanDataFilter({ ...filterObj, [lable]: [e.target.value] })
        );
      } else {
        // if key exist then add the value
        dispatch(
          setOceanDataFilter({
            ...filterObj,
            [lable]: [...filterObj[lable], e.target.value],
          })
        );
      }
    } else {
      // remove the value while unchecking
      const filterValue = filterObj[lable].filter(
        (value) => value !== e.target.value
      );
      dispatch(setOceanDataFilter({ ...filterObj, [lable]: [...filterValue] }));
    }
  };

  // active filter checked
  const handelCheckbox = (lable, value) => {
    return filterObj[lable] && filterObj[lable].find((v) => v === value);
  };

  const handleFilteredDatasets = () => {
    // creating filterObj into string and dispatch the API action
    let paramObj = {};
    if (Object.keys(filterObj).length) {
      Object.keys(filterObj).forEach((filterKey) => {
        paramObj[filterKey] = filterObj[filterKey].join(",");
      });
      dispatch(getOceanFilterDataset(paramObj));
    }
    setIsFilter(false);
  };

  return (
    <div className="filter-container">
      <div className="filter-header">
        <span>Filters</span>
        <RxCross1 onClick={() => setIsFilter(false)} className="cross" />
      </div>
      <div className="filter-section">
        {OCEAN_DATA_FILTERS.map((filter) => {
          return (
            <div key={filter.lable} className="filter-type">
              <span>{filter.lable}</span>
              <GoQuestion
                size={20}
                style={{ opacity: "40%", margin: "0px 0px 5px 5px" }}
              />
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  fontFamily: "Roboto",
                }}
              >
                {filter.options.map((option) => (
                  <CheckList
                    key={option.title}
                    title={option.title}
                    value={option.value}
                    lable={filter.value}
                    handleFilters={handleFilters}
                    isChecked={handelCheckbox(filter.value, option.value)}
                  />
                ))}
              </div>
            </div>
          );
        })}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button onClick={handleFilteredDatasets} className="filter-selection">
            Show Results
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectComponent;

const CheckList = ({ title, value, lable, handleFilters, isChecked }) => {
  return (
    <div className="filter-tag">
      <input
        type="checkbox"
        id={title}
        value={value}
        // check if it already in filterObj or not
        checked={Boolean(isChecked)}
        onChange={(e) => handleFilters(e, lable)}
        style={{ marginRight: "5px" }}
      />
      <label htmlFor={title}>{title}</label>
    </div>
  );
};
