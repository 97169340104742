import React from 'react'
import "../styles/imgTitleComponent.scss"
const ImgTitleComponent = () => {
  return (
    <div className='img-title-container m-0 p-0'>
      <div className='img-section p-0'><img src='./square.png'/></div>
      <div className='heading-container'>
        <div className='main-heading'>Hurricane Event Data</div>
        <div className='sub-heading'>NOAA</div>
      </div>
    </div>
  )
}

export default ImgTitleComponent
