import React, { useState } from "react";
import "../../styles/searchBarComponent.scss";
import { ImgSearch } from "../../images";
import PillButton from "./pillButton";

const SearchBarComponent = ({ setSearchText }) => {
  const [inputValue, setInputValue] = useState("");
  const [keywords, setKeywords] = useState([]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      const newKeyword = [...keywords, inputValue.trim()];
      setKeywords(newKeyword);
      setSearchText(newKeyword.join(","));
      setInputValue("");
    }
  };

  const removeKeyword = (keyword) => {
    const updatedKeywords = keywords.filter((k) => k !== keyword);
    setKeywords(updatedKeywords);
    setSearchText(updatedKeywords.join(","));
  };
  return (
    <div className="search-bar">
      <div className="pill-list">
        {keywords.map((keyword, index) => (
          <PillButton
            key={index}
            keywordObj={{ Variable_Level_1: keyword }}
            removeKeyword={() => removeKeyword(keyword)}
          />
        ))}
        <input
          type="text"
          placeholder="Search"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      <img className="search-icon" src={ImgSearch} />
    </div>
  );
};

export default SearchBarComponent;
