import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import '../../styles/modalComponent.scss';
import '../../styles/datasetDescriptionEditModal.scss'
import { getVersionString } from '../../utils/utils';
import DatasetPreviewComponent from './datasetPreviewComponent';
import HgButtonComponent from './hgButtonComponent';
import SafeHtmlComponent from './safeHtmlComponent';
import {Button} from "@mui/material"
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import Switch from '@mui/material/Switch';


const CustomTextField = styled(TextField)({
    marginLeft:'50px',
    display: "flex",
    height: "200px",
    width: "100%",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: "1px dashed #51606F",
    backgroundColor: "#FCFCFF",
    boxShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    "& .MuiInputBase-root": {
      height: "100%",
      padding: "0",
    },
    "& .MuiInputBase-input": {
      padding: "0 15px",
      fontFamily: "SpaceGrotesk",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
    },
  });

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

function DatasetDescriptionEditPopupComponent(props) {
    const [isToggled, setIsToggled] = useState(false);
    const handleToggle = (event) => {
        setIsToggled(event.target.checked);
      };
    const { description, toggle,  modal, onAccept, name, version} = props

    return (
        <div className='view-dataset-edit-description-component'>
            <Modal style={{width:'800px'}} isOpen={modal} toggle={toggle} size={"md"} className={"view-dataset-edit-description-component"}  {...props}>
                <ModalHeader toggle={toggle}>Edit Description
                </ModalHeader>
                <ModalBody>

                <div className="content">
                    <div className="label-text-2">Dataset Title</div>
                  <div className="div-wrapper">
                    <p className="input-text-2">{name}</p>
                  </div>
                </div>

                <div className="content">
                    <div className="label-text-2">Dataset Version</div>
                  <div className="div-wrapper">
                    <p className="input-text-2">{version}</p>
                  </div>
                </div>



                <div className="content">
                    <div className="label-text-3">Dataset Description</div>
                </div>

                
                <div className="div-wrapper100">
              <div className="label-text-201">Description</div>
            </div>
            <div style={{ marginTop: "12px" }}>
              <CustomTextField
                // value={this.state.attribution}
                // onChange={this.handleAttribution}
              />
            </div>


            <div className="div-wrapper100">
              <div className="label-text-201">Additional Version Notes</div>
            </div>
            <div style={{ marginTop: "12px" }}>
              <CustomTextField
              style={{height:'104px'}}
                // value={this.state.attribution}
                // onChange={this.handleAttribution}
              />
            </div>
                    {/* <div style={{wordWrap:'break-word', marginLeft:'46px'}} className='pd-8'><SafeHtmlComponent data={description} /></div> */}
                </ModalBody>
                <ModalFooter>
                    <div className='togglebtn'>   
                <div
                style={{display:"flex", alignItems:'center'}} >
      <Switch
        checked={isToggled}
        onChange={handleToggle}
        color="primary" // 'primary', 'secondary', or 'default'
      />
      <div variant="body1">
        {isToggled ? "Changes applied to all the versions" : "Apply changes to all versions"}
      </div>
    
    </div>
                    {/* <HgButtonComponent buttonText={"Close"} isPrimary={false} onclickHandler={() => onAccept()} /> */}
                    <Button
                    className='btnspecial'
                variant="outlined"
                // onClick={this.handleNext}
              >
                EDIT
              </Button>

              </div>
                </ModalFooter>
            </Modal>
            </div>
    );
}

export default DatasetDescriptionEditPopupComponent;