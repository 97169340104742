import React, { useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import '../../styles/richTextEditorComponent.scss';


export const RichTextEditor = ({ onTextChange, defaultText }) => {
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            // ['blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            // [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean']
        ],
    };
    const placeholder = 'Clam is a common name for several kinds of bivalve';
    const { quill, quillRef } = useQuill({ modules, placeholder });

    useEffect(() => {
        if (quill) {
            quill.on('text-change', () => {
                onTextChange(quill.root.innerHTML);
            });
        }
    }, [quill]);

    useEffect(() => {
        try {
            const delta = quill.clipboard.convert(defaultText);
            quill.setContents(delta, 'silent');
            onTextChange(defaultText);
        } catch {
            // eating up here
        }
        
    }, [quill, defaultText]);

    return (
        <div style={{ height: '250px' }}>
            <div ref={quillRef} />
        </div>
    );
};

export default RichTextEditor;