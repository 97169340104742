import React, { forwardRef, useEffect, useState, memo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ImgTrash, ImgPlusDark, ImgCross, ImgSearch } from "../../images";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/searchComponent.scss";
import moment from "moment";
import { FILE_TYPES, DATASET_STATUS, VISIBILITY } from "../../config";
import HgDropdownComponent from "./hgDropdownComponent";
import * as datasetActions from "../../actions/datasetActions";

const STATUS_OPTIONS = [
  { label: "CREATED", value: DATASET_STATUS.CREATED },
  { label: "PROCESSING", value: DATASET_STATUS.PROCESSING },
  { label: "PROCESSED", value: DATASET_STATUS.PROCESSED },
  { label: "PROCESSING FAILED", value: DATASET_STATUS.PROCESSING_FAILED },
];

export const DsgSearchComponent = (props) => {
  const { tagsChangeHandler, defaultTags, visibility } = props;
  const [tagsList, updateTagsList] = useState([]);
  const [showInputKeyword, setShowInputKeyword] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [validate, setValdate] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  /**
   * Datepicker
   */
  const [startDateCreated, setStartDateCreated] = useState(new Date());
  const [endDateCreated, setEndDateCreated] = useState(null);

  const [startDateLastModified, setStartDateLastModified] = useState(
    new Date()
  );
  const [endDateLastModified, setEndDateLastModified] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState();

  const updateTagValue = (e) => {
    setTagValue(e.target.value);
  };

  const keyDown = (e) => {
    if ((e.keyCode === 13 || e.key === "Enter") && tagValue.trim()) {
      addTag(tagValue.toLowerCase());
    }
  };

  const addTag = (tagValue, type = "keyword") => {
    setValdate(true);
    const itemExist = tagsList.find((tag) => {
      if (type === "keyword" || type === "fileType" || type === "status") {
        return (
          tag.value.toLowerCase() === tagValue.toLowerCase() &&
          tag.type === type
        );
      }
      if (type === "crDate" || type === "lmDate") {
        return tag;
      }
      // return tag.value.toLowerCase() === tagValue.toLowerCase() && tag.type === type
    });
    if (!itemExist) {
      updateTagsList([...tagsList, { value: tagValue, type: type }]);
      setShowInputKeyword(false);
      setTagValue("");
    } else {
      // setChipExists(true);
    }
  };

  const removeTag = (index) => {
    setValdate(true);
    const filteredtags = tagsList.filter((chip, i) => {
      if (index === i) {
        if (chip.type === "crDate") {
          setEndDateCreated(null);
        }
        if (chip.type === "lmDate") {
          setEndDateLastModified(null);
        }
      }
      return index !== i;
    });
    updateTagsList(filteredtags);
  };

  useEffect(() => {
    if (tagsList.length === 0) {
      if (!isFirstRender) {
        composeSearchObject(true);
      } else {
        setIsFirstRender(false);
      }
    } else {
      composeSearchObject();
    }
  }, [tagsList]);

  const CreationDateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="filter creation-date" onClick={onClick} ref={ref}>
      Created On
    </div>
  ));

  const LastModifiedDateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="filter creation-date" onClick={onClick} ref={ref}>
      Last Modified
    </div>
  ));

  const onChangeCreatedDate = (dates) => {
    const [start, end] = dates;
    setStartDateCreated(start);
    setEndDateCreated(end);
    if (end) {
      addTag(dateTag(start, end, "Created on:"), "crDate");
    }
  };

  const onChangeLastModifiedDate = (dates) => {
    const [start, end] = dates;
    setStartDateLastModified(start);
    setEndDateLastModified(end);
    if (end) {
      addTag(dateTag(start, end, "Last modified:"), "lmDate");
    }
  };

  const dateTag = (start, end, prefix) => {
    return `${prefix} ${moment(start).format("DD MMM YYYY")} - ${moment(
      end
    ).format("DD MMM YYYY")}`;
  };

  const composeSearchObject = (isEmpty = false) => {
    const obj = {};

    const keywordsMap = tagsList
      .filter((item) => {
        if (item.type === "keyword") {
          return item.value;
        }
      })
      .map((item) => item.value);
    if (keywordsMap.length > 0) {
      obj["search_keywords"] = keywordsMap.join(",");
    }

    if (endDateCreated) {
      obj["creation_date_range"] = JSON.stringify([
        `${moment(startDateCreated).format("YYYY-MM-DD")}`,
        `${moment(endDateCreated).format("YYYY-MM-DD")}`,
      ]);
    }

    if (endDateLastModified) {
      obj["last_modified_date_range"] = JSON.stringify([
        `${moment(startDateLastModified).format("YYYY-MM-DD")}`,
        `${moment(endDateLastModified).format("YYYY-MM-DD")}`,
      ]);
    }

    const typesList = tagsList
      .filter((item) => {
        if (item.type === "fileType") {
          return item;
        }
      })
      .map((item) => item.value);
    if (typesList.length) {
      obj["dataset_format"] = typesList.join(",");
    }

    const statusList = tagsList
      .filter((item) => {
        if (item.type === "status") {
          return item;
        }
      })
      .map((item) => item.value);
    if (statusList.length) {
      obj["status"] = statusList.join(",");
    }

    console.log(obj);
    if (Object.keys(obj).length > 0) {
      tagsChangeHandler(obj);
    } else if (isEmpty) {
      tagsChangeHandler({});
    }
  };

  const onTypeSelect = (item) => {
    console.log(item);
    addTag(item.value, "fileType");
  };

  const onStatusSelect = (item) => {
    setSelectedStatus(item);
    addTag(item.value, "status");
  };
  return (
    <div className="search-component">
      <div className="search-keywords-list">
        <ul>
          {tagsList.map((item, i) => {
            return (
              <li key={`${item}${i}`}>
                {item.value}{" "}
                <img
                  src={ImgCross}
                  alt="delete"
                  onClick={() => removeTag(i)}
                  width={14}
                />
              </li>
            );
          })}

          <input
            type="text"
            className="tags-input"
            placeholder="Search"
            onChange={updateTagValue}
            onKeyDown={keyDown}
            value={tagValue}
          />
          {/* <div className="input-toggler" onClick={() => setShowInputKeyword(false)}></div> */}
        </ul>
        <img
          src={ImgSearch}
          className="search-icon"
          onClick={() => composeSearchObject()}
        />
      </div>
      <div className="filters">
        {/* <div className="filter creation-date">Created On</div> */}
        <DatePicker
          id="createdOn"
          selected={startDateCreated}
          startDate={startDateCreated}
          endDate={endDateCreated}
          onChange={onChangeCreatedDate}
          customInput={<CreationDateInput />}
          dateFormat="dd MMM yyyy"
          selectsRange
          showMonthDropdown={true}
          showYearDropdown={true}
        />

        <DatePicker
          id="lastModified"
          selected={startDateLastModified}
          startDate={startDateLastModified}
          endDate={endDateLastModified}
          onChange={onChangeLastModifiedDate}
          customInput={<LastModifiedDateInput />}
          dateFormat="dd MMM yyyy"
          selectsRange
          showMonthDropdown={true}
          showYearDropdown={true}
        />
        {/* <div className="filter modified-date">Last Modified</div> */}
        <HgDropdownComponent
          listItems={FILE_TYPES}
          placeholder={"Type"}
          // customListComponent={this.selectContentTypeList}
          onSelectHandler={onTypeSelect}
          customClass={"search-type"}
          // defaultValue={this.state.isEditDataset ?  this.props.dataset.datasetInfo.visibility.toLowerCase() : ''}
        />
        {/* <div className="filter type-date">Type</div> */}

        {visibility === VISIBILITY.PRIVATE && (
          <HgDropdownComponent
            listItems={STATUS_OPTIONS}
            placeholder={"Status"}
            onSelectHandler={onStatusSelect}
            customClass={"search-type"}
          />
        )}
      </div>
    </div>
  );
};

export default memo(DsgSearchComponent);
