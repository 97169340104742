import DatasetApi from "../api/datasetApi";
import { DATASET_ACTIONS } from "./actionTypes";
import {
  DATASET_OBJECT,
  DATASET_STATUS,
  DOCUMENT_ROOT,
  ROOT_DATASET_API_RESPONSE_OBJ,
  VISIBILITY,
} from "../config";
import { push } from "connected-react-router";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export function getRootDatasetsList(
  visibility = VISIBILITY.PRIVATE,
  filters = {},
  page = 1
) {
  return (dispatch) => {
    dispatch(getRootDatasetsListLoading());
    return DatasetApi.getrootDatasets(visibility, filters, page)
      .then((resp) => {
        console.log(resp);
        let tempAllDatasets;
        if (resp.length === 2) {
          tempAllDatasets = resp[0].data.results
            .concat(resp[1].data.results)
            .filter((dataset) => dataset.latest_version.status !== "DELETED");
        } else {
          tempAllDatasets = resp[0].data.results.filter(
            (dataset) => dataset.latest_version.status !== "DELETED"
          );
        }
        tempAllDatasets = tempAllDatasets
          .map((item) => {
            if (item.shared_on) {
              item["sorting_date"] = moment(item.shared_on).format(
                "YYYYMMDDHHmmssSSS"
              );
            } else if (item.creation_date) {
              item["sorting_date"] = moment
                .unix(item.creation_date)
                .format("YYYYMMDDHHmmssSSS");
            }
            return item;
          })
          .sort((a, b) => {
            return b.sorting_date - a.sorting_date;
          });
        const { data } = resp[0];
        console.log(data);
        data.results = tempAllDatasets;
        dispatch(getRootDatasetsListSuccess(data));
      })
      .catch((error) => {
        console.log(`getRootDatasetsList ${error}`);
        dispatch(getRootDatasetsListError());
      });
  };
}

export function filterDatasets(visibility, filter, page = 1) {
  return function (dispatch) {
    dispatch(getRootDatasetsListLoading());
    DatasetApi.getFilteredDataset(visibility, filter, page)
      .then((res) => {
        dispatch(getRootDatasetsListSuccess(res.data));
      })
      .catch((error) => {
        console.error("Error fetching filtered datasets", error);
        dispatch(getRootDatasetsListError());
      });
  };
  // API call for filter
}

function getRootDatasetsListLoading() {
  return { type: DATASET_ACTIONS.ROOT_DATASETS_LOADING };
}

function getRootDatasetsListSuccess(payload) {
  return { type: DATASET_ACTIONS.ROOT_DATASETS_SUCCESS, payload };
}

function getRootDatasetsListError() {
  return { type: DATASET_ACTIONS.ROOT_DATASETS_ERROR };
}

export function resetRootDatasetsList() {
  const payload = { ...ROOT_DATASET_API_RESPONSE_OBJ };
  return { type: DATASET_ACTIONS.ROOT_DATASETS_SUCCESS, payload };
}

export function getRootDatasetInfo(rootDatasetId, callback = () => {}) {
  return (dispatch) => {
    dispatch(getRootDatasetInfoLoading());
    return DatasetApi.getRootDatasetInfo(rootDatasetId)
      .then(({ data }) => {
        callback(
          data["latest_version"]["dataset_preview_url"],
          data["latest_version"]["dataset_schema"]
        );
        dispatch(getRootDatasetInfoSuccess(data));
      })
      .catch((error) => {
        console.log(`getRootDatasetInfo ${error}`);
        dispatch(getRootDatasetInfoError());
      });
  };
}

function getRootDatasetInfoLoading() {
  return { type: DATASET_ACTIONS.ROOT_DATASET_INFO_LOADING };
}

function getRootDatasetInfoSuccess(payload) {
  return { type: DATASET_ACTIONS.ROOT_DATASET_INFO_SUCCESS, payload };
}

function getRootDatasetInfoError() {
  return { type: DATASET_ACTIONS.ROOT_DATASET_INFO_ERROR };
}

export function getRootDatasetInfoReset() {
  return { type: DATASET_ACTIONS.ROOT_DATASET_INFO_SUCCESS, DATASET_OBJECT };
}

function getOceanDatasetsListLoading() {
  return { type: DATASET_ACTIONS.OCEAN_DATASETS_LOADING };
}

function getOceanDatasetListSuccess(payload) {
  return { type: DATASET_ACTIONS.OCEAN_DATASET_LIST_SUCCESS, payload };
}

function getOceanDatasetListError() {
  return { type: DATASET_ACTIONS.OCEAN_DATASET_LIST_ERROR };
}

export function setOceanDataFilter(payload) {
  return { type: DATASET_ACTIONS.OCEAN_DATA_SET_FILTER, payload };
}

function getOceanFilterDatasetLoading() {
  return { type: DATASET_ACTIONS.OCEAN_FILTER_DATASETS_LOADING };
}

function getOceanFilterDatasetSuccess(payload) {
  return { type: DATASET_ACTIONS.OCEAN_FILTER_DATASETS_SUCCESS, payload };
}

function getOceanFilterDatasetError() {
  return { type: DATASET_ACTIONS.OCEAN_FILTER_DATASETS_FALIURE };
}

export function getOceanDataset(visibility = VISIBILITY.OCEANOFDATA, page = 1) {
  return function (dispatch) {
    dispatch(getOceanDatasetsListLoading());
    return DatasetApi.getOceanDataset(visibility, page)
      .then((res) => {
        dispatch(getOceanDatasetListSuccess(res[0].data));
      })
      .catch((error) => {
        console.error("Error fetching datasets", error);
        dispatch(getOceanDatasetListError());
      });
  };
}

export function getOceanFilterDataset(
  filterObj,
  visibility = VISIBILITY.OCEANOFDATA
) {
  return function (dispatch) {
    dispatch(getOceanFilterDatasetLoading());
    DatasetApi.getOceanFilterDataset(visibility, filterObj)
      .then((res) => {
        dispatch(getOceanFilterDatasetSuccess(res.data));
      })
      .catch((error) => {
        console.error("Error fetching datasets", error);
        dispatch(getOceanFilterDatasetError());
      });
  };
}

export function addDataset(
  name,
  description = "",
  s3_url,
  prj_s3_url,
  visibility,
  dataset_format,

  attribution = "",
  website = "",
  latitude,
  longitude,
  license_slug = "",
  version_choice = "",
  version = "",
  restrict_share_public,
  restrict_share_org,
  license_url
) {
  return (dispatch) => {
    dispatch(addDatasetLoading());
    DatasetApi.addDataset(
      name,
      description,
      s3_url,
      prj_s3_url,
      visibility,
      dataset_format,

      attribution,
      website,
      latitude,
      longitude,
      license_slug,
      version_choice,
      version,
      restrict_share_public,
      restrict_share_org,
      license_url
    )
      .then(({ data }) => {
        console.log(data);
        dispatch(addDatasetSuccess(data));
        dispatch(push(`${DOCUMENT_ROOT}my-datasets`));
      })
      .catch((error) => {
        console.log(`addDataset ${error}`);
        dispatch(addDatasetError());
      });
  };
}

function addDatasetLoading() {
  return { type: DATASET_ACTIONS.ADD_DATASET_LOADING };
}

function addDatasetSuccess(payload) {
  return { type: DATASET_ACTIONS.ADD_DATASET_SUCCESS, payload };
}

function addDatasetError() {
  return { type: DATASET_ACTIONS.ADD_DATASET_ERROR };
}

export function updateRootDatasetInfo(
  name,
  visibility,
  description = "",
  tags = [],
  rootdatasetId
) {
  return (dispatch) => {
    dispatch(updateRootDatasetInfoLoading());
    return DatasetApi.updateRootDatasetInfo(
      name,
      visibility,
      description,
      tags,
      rootdatasetId
    )
      .then(({ data }) => {
        dispatch(updateRootDatasetInfoSuccess(data));
        dispatch(push(`${DOCUMENT_ROOT}view-dataset/${rootdatasetId}`));
      })
      .catch((error) => {
        console.log(`updateRootDatasetInfo ${error}`);
        dispatch(updateRootDatasetInfoError());
      });
  };
}

function updateRootDatasetInfoLoading() {
  return { type: DATASET_ACTIONS.UPDATE_DATASET_LOADING };
}

function updateRootDatasetInfoSuccess() {
  return { type: DATASET_ACTIONS.UPDATE_DATASET_SUCCESS };
}

function updateRootDatasetInfoError() {
  return { type: DATASET_ACTIONS.UPDATE_DATASET_ERROR };
}

export function updateDatasetStatus(datasetIds, status, callback = () => {}) {
  return (dispatch) => {
    dispatch(updateDatasetStatusLoading());
    return DatasetApi.updateDatasetStatus(datasetIds, status)
      .then(({ data }) => {
        dispatch(updateDatasetStatusSuccess({ datasetIds, status }));
        callback();
      })
      .catch((error) => {
        console.log(`updateDatasetStatus ${error}`);
        dispatch(updateDatasetStatusError());
      });
  };
}

function updateDatasetStatusLoading() {
  return { type: DATASET_ACTIONS.UDPATE_DATASET_STATUS_LOADING };
}

function updateDatasetStatusSuccess(payload) {
  return { type: DATASET_ACTIONS.UDPATE_DATASET_STATUS_SUCCESS, payload };
}

function updateDatasetStatusError() {
  return { type: DATASET_ACTIONS.UDPATE_DATASET_STATUS_ERROR };
}

export function shareDataset(rootDatasetId, data, status, callback = () => {}) {
  console.log(rootDatasetId, "checking from datasetActions:::");
  console.log("Data sending to share:", data);
  return (dispatch) => {
    dispatch(shareDatasetLoading());
    if (status !== DATASET_STATUS.PUBLISHED) {
      return DatasetApi.updateDatasetStatus(
        rootDatasetId,
        DATASET_STATUS.PUBLISHED
      ).then((res) => {
        console.log(rootDatasetId, "dataset published confirmation check::");
        DatasetApi.shareDataset(data)
          .then(({ data }) => {
            dispatch(shareDatasetSuccess());
            dispatch(getDatasetSharedUsers(rootDatasetId));
            callback();
          })
          .catch((error) => {
            console.log(`shareDataset ${error}`);
            dispatch(shareDatasetError());
          });
      });
    }
    return DatasetApi.shareDataset(data)
      .then(({ data }) => {
        console.log(rootDatasetId, "humfirst::", data);
        dispatch(shareDatasetSuccess());
        dispatch(getDatasetSharedUsers(rootDatasetId));
        callback();
      })
      .catch((error) => {
        console.log(`shareDataset ${error}`);
        dispatch(shareDatasetError());
      });
  };
}

export function shareMultipleDataset(
  rootDatasetId,
  data,
  selectedDatasets,
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(shareDatasetLoading());
    return DatasetApi.updateDatasetStatus(
      selectedDatasets,
      DATASET_STATUS.PUBLISHED
    ).then((res) => {
      DatasetApi.shareDataset(data)
        .then(({ data }) => {
          dispatch(shareDatasetSuccess());
          dispatch(getDatasetSharedUsers(rootDatasetId));
          callback();
        })
        .catch((error) => {
          console.log(`shareDataset ${error}`);
          dispatch(shareDatasetError());
        });
    });
  };
}

function shareDatasetLoading() {
  return { type: DATASET_ACTIONS.SHARE_DATASET_LOADING };
}

function shareDatasetSuccess() {
  return { type: DATASET_ACTIONS.SHARE_DATASET_SUCCESS };
}

function shareDatasetError() {
  return { type: DATASET_ACTIONS.SHARE_DATASET_ERROR };
}

export function getDatasetVerionsList(rootDatasetId) {
  return (dispatch) => {
    dispatch(getDatasetVerionsListLoading());
    return DatasetApi.getDatasetVerionsList(rootDatasetId)
      .then(({ data }) => {
        dispatch(getDatasetVerionsListSuccess(data.results));
      })
      .catch((error) => {
        console.log(`getDatasetVerionsList ${error}`);
        dispatch(getDatasetVerionsListError());
      });
  };
}

function getDatasetVerionsListLoading() {
  return { type: DATASET_ACTIONS.DATASET_VERSIONS_LOADING };
}

function getDatasetVerionsListSuccess(payload) {
  return { type: DATASET_ACTIONS.DATASET_VERSIONS_SUCCESS, payload };
}

function getDatasetVerionsListError() {
  return { type: DATASET_ACTIONS.DATASET_VERSIONS_ERROR };
}

export function getDatasetVersionInfo(datasetId, callback = () => {}) {
  return (dispatch) => {
    dispatch(getDatasetVersionInfoLoading());
    return DatasetApi.getDatasetVersionInfo(datasetId)
      .then(({ data }) => {
        callback(data["dataset_preview_url"]);
        dispatch(getDatasetVersionInfoSuccess(data));
      })
      .catch((error) => {
        console.log(`getDatasetVersionInfo ${error}`);
        dispatch(getDatasetVersionInfoError());
      });
  };
}

function getDatasetVersionInfoLoading() {
  return { type: DATASET_ACTIONS.DATASET_VERSION_INFO_LOADING };
}

function getDatasetVersionInfoSuccess(payload) {
  return { type: DATASET_ACTIONS.DATASET_VERSION_INFO_SUCCESS, payload };
}

function getDatasetVersionInfoError() {
  return { type: DATASET_ACTIONS.DATASET_VERSION_INFO_ERROR };
}

export function downloadDataset(datasets = [], callback = () => {}) {
  return (dispatch) => {
    dispatch(downloadDatasetLoading());
    return DatasetApi.downloadDataset(datasets)
      .then(({ data }) => {
        callback(data);
        dispatch(downloadDatasetSuccess());
      })
      .catch((error) => {
        console.log(`downloadDataset ${error}`);
        dispatch(downloadDatasetError());
      });
  };
}

function downloadDatasetLoading() {
  return { type: DATASET_ACTIONS.DATASET_DOWNLOAD_LOADING };
}

function downloadDatasetSuccess() {
  return { type: DATASET_ACTIONS.DATASET_DOWNLOAD_SUCCESS };
}

function downloadDatasetError() {
  return { type: DATASET_ACTIONS.DATASET_DOWNLOAD_ERROR };
}

export function getDatasetSharedUsers(rootDatasetId) {
  console.log(rootDatasetId, "idchecked::");
  return (dispatch) => {
    dispatch(getDatasetSharedUsersLoading());
    return DatasetApi.getDatasetSharedUsers(rootDatasetId)
      .then(({ data }) => {
        console.log(data, "getDatasetSharedUsers", rootDatasetId);
        dispatch(
          getDatasetSharedUsersSuccess(
            data.shared_users.map((item) => {
              console.log(item, "itemmmmm");
              item.uuid = uuidv4();
              return item;
            })
          )
        );
      })
      .catch((error) => {
        console.log(`getDatasetSharedUsers ${error}`);
        dispatch(getDatasetSharedUsersError());
      });
  };
}

function getDatasetSharedUsersLoading() {
  return { type: DATASET_ACTIONS.DATASET_SHARED_USERS_LOADING };
}

function getDatasetSharedUsersSuccess(payload) {
  return { type: DATASET_ACTIONS.DATASET_SHARED_USERS_SUCCESS, payload };
}

function getDatasetSharedUsersError() {
  return { type: DATASET_ACTIONS.DATASET_SHARED_USERS_ERROR };
}

export function datasetShareEnableDisble(userId, datasetId, isEnabled) {
  return (dispatch) => {
    dispatch(datasetShareEnableDisbleLoading());
    return DatasetApi.datasetShareEnableDisble(userId, datasetId, isEnabled)
      .then(({ data }) => {
        dispatch(datasetShareEnableDisbleSuccess());
      })
      .catch((error) => {
        console.log(`datasetShareEnableDisble ${error}`);
        dispatch(datasetShareEnableDisbleError());
      });
  };
}

function datasetShareEnableDisbleLoading() {
  return { type: DATASET_ACTIONS.DATASET_SHARE_ENABLE_DISABLE_LOADING };
}

function datasetShareEnableDisbleSuccess() {
  return { type: DATASET_ACTIONS.DATASET_SHARE_ENABLE_DISABLE_SUCCESS };
}

function datasetShareEnableDisbleError() {
  return { type: DATASET_ACTIONS.DATASET_SHARE_ENABLE_DISABLE_ERROR };
}

export function addDatasetVersion(
  rootDatasetId,
  s3Url,
  visibility,
  datasetFormat,
  tags,
  comment,
  versionAlias
) {
  return (dispatch) => {
    dispatch(addDatasetVersionLoading());
    return DatasetApi.addDatasetVersion(
      rootDatasetId,
      s3Url,
      visibility,
      datasetFormat,
      tags,
      comment,
      versionAlias
    )
      .then(({ data }) => {
        dispatch(addDatasetVersionSuccess(data));
        dispatch(push(`${DOCUMENT_ROOT}view-dataset/${rootDatasetId}`));
      })
      .catch((error) => {
        console.log(`addDatasetVersion ${error}`);
        dispatch(addDatasetVersionError());
      });
  };
}

function addDatasetVersionLoading() {
  return { type: DATASET_ACTIONS.ADD_DATASET_VERSION_LOADING };
}

function addDatasetVersionSuccess() {
  return { type: DATASET_ACTIONS.ADD_DATASET_VERSION_SUCCESS };
}

function addDatasetVersionError() {
  return { type: DATASET_ACTIONS.ADD_DATASET_VERSION_ERROR };
}
