import React, { useEffect, useState } from "react";
import DataCategoryGradientComponent from "./pure/dataCategoryGradientComponent";
import SearchBarComponent from "./pure/searchBarComponent";
import { ImgTBBCLogo } from "../images";
import { GoChevronRight } from "react-icons/go";
import { GoPlus } from "react-icons/go";
import "../styles/oceanData.scss";
import GridViewDatasetComponent from "./pure/gridViewDatasetComponent";
import SelectComponent from "./pure/selectComponent";
import { OCEAN_DATA_FILTERS, SIDEBAR_TABS, VISIBILITY } from "../config";
import ListViewDatasetComponent from "./pure/listViewDatasetComponent";
import { BsGrid } from "react-icons/bs";
import { HiOutlineViewList } from "react-icons/hi";
import { getOceanDataset, getOceanFilterDataset, setOceanDataFilter } from "../actions/datasetActions";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./loaderComponent";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SidebarComponent from "./pure/sidebarComponent";

const OceanData = () => {
  const [isListView, setIsListView] = useState(false);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOceanDataset());
  }, []);

  const { oceanDatasets, isDatasetsListLoading, filterObj } = useSelector((state) => ({
    oceanDatasets: state.dataset.oceanDatasets,
    isDatasetsListLoading: state.dataset.isDatasetsListLoading,
    filterObj: state.dataset.oceanDataFilter,
  }));

  const handleSearch = (searchText) => {

    const updatedFilterObj = {
      ...filterObj,
      ...(searchText ? { search_keywords: [searchText] } : { search_keywords: [] }), 
    };
  
    const paramObj = Object.keys(updatedFilterObj).reduce((acc, key) => {
      if (updatedFilterObj[key].length > 0) {
        acc[key] = updatedFilterObj[key].join(","); 
      }
      return acc;
    }, {});
  
    dispatch(setOceanDataFilter(updatedFilterObj)); 
    dispatch(getOceanFilterDataset(paramObj));
  }

  const [isFilter, setIsFilter] = useState(false);
  return (
    <div className="ocean-of-data">
      <Link to={'/'}>
      <div className="header" style={{paddingLeft: '128px'}}>
        <img className="big-blue-cloud" src={ImgTBBCLogo} alt="LOGO" />
      </div>
      </Link>
      <SidebarComponent activeTab={SIDEBAR_TABS.oceanDatasets}/>
      <div className="main">
        <div className="ocean-data">Ocean of Data</div>
        <DataCategoryGradientComponent />
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <button className="filter-btn" onClick={() => setIsFilter(true)}>
              <GoPlus /> Filters
            </button>
            {isFilter && (
              <>
                <div className="backdrop">
                  <div className="filter-popup">
                    <SelectComponent setIsFilter={setIsFilter} />
                  </div>
                </div>
              </>
            )}

            <SearchBarComponent setSearchText={handleSearch}/>
          </div>
          <div style={{ display: "flex", marginBottom: "18px" }}>
            <BsGrid
              onClick={() => setIsListView(false)}
              size={25}
              style={{
                color: `${!isListView ? "#004A75" : "#D5E4F6"}`,
                cursor: "pointer",
                marginRight: "15px",
              }}
            />
            <HiOutlineViewList
              style={{
                color: `${isListView ? "#004A75" : "#D5E4F6"}`,
                cursor: "pointer",
              }}
              onClick={() => setIsListView(true)}
              size={25}
            />
          </div>
        </div>
        <div className="datasets">
          <div className="heading">Popular Datasets</div>
          <div className="view-all">
            <div className="view-all-text">View All</div>
            <div className="next-icon">
              <GoChevronRight />
            </div>
          </div>
        </div>
        {!isListView ? (
          <div className="popular-datasets-grid">
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
          </div>
        ) : (
          <div className="popular-datasets-list">
            <ListViewDatasetComponent />
            <ListViewDatasetComponent />
            <ListViewDatasetComponent />
          </div>
        )}
        {!isListView && <div className="seperation-line"></div>}
        <div className="datasets">
          <div className="heading">All Datasets</div>
          <div className="view-all">
            <div className="view-all-text">View All</div>
            <div className="next-icon">
              <GoChevronRight />
            </div>
          </div>
        </div>
        {isDatasetsListLoading ? (
          <Loader />
        ) : (
          <>
            {!isListView ? (
              <div className="all-datasets-grid">
                {oceanDatasets.results.map((item) => (
                  <GridViewDatasetComponent
                    key={item.uid}
                    title={item.name}
                    description={item.description}
                    payment={item.payment}
                    license={item.license}
                    dataset_format={item.dataset_format}
                    data_type={item.data_type}
                  />
                ))}
              </div>
            ) : (
              <div className="all-datasets-list">
                {oceanDatasets.results.map((item) => (
                  <ListViewDatasetComponent
                    key={item.uid}
                    title={item.name}
                    description={item.description}
                    payment={item.payment}
                    license={item.license}
                    data_type={item.data_type}
                  />
                ))}
              </div>
            )}
          </>
        )}
        {!isListView && !isDatasetsListLoading && (
          <div className="seperation-line"></div>
        )}
      </div>
    </div>
  );
};

export default OceanData;
