import { Modal } from "reactstrap";
import "../styles/viewVersionsPopup.scss";
import React, { useState } from "react";
import "../styles/manageVersions.scss";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
const ViewVersionsPopupComponent = (props) => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [activeIndex, setActiveIndex] = useState(null);
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 50,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "rgba(0, 99, 154, 1)",
          opacity: 1,
          border: 0,
          ...theme.applyStyles("dark", {
            backgroundColor: "rgba(0, 99, 154, 1)",
          }),
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
        ...theme.applyStyles("dark", {
          color: theme.palette.grey[600],
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
        ...theme.applyStyles("dark", {
          opacity: 0.3,
        }),
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      ...theme.applyStyles("dark", {
        backgroundColor: "#39393D",
      }),
    },
  }));
  return (
    <div className="version-popup-container">
      <Modal
        className="manage-versions-modal"
        isOpen={props.versionOpen}
        toggle={props.toggleVersionModal}
      >
        <div className="manage-versions-container">
          <h2>Manage Versions</h2>
          <div className="manage-versions-desc">
            <p>
              Following datasets are processing in the background. Please do not
              close or exit your browser window to ensure the upload completes
              successfully.
            </p>
          </div>
          <div className="manage-versions-upload-section">
            <button>Upload New Version</button>
          </div>

          {/* Version 1 */}

          {[0, 1, 2].map((currItem, idx) => {
            return (
              <div className="manage-versions-version-item" key={idx}>
                <div className="manage-versions-version-info">
                  <div className="manage-versions-info-divs">
                    <p className="manage-versions-info-titles">Version</p>
                    <p className="manage-versions-info-details">Version 1</p>
                  </div>
                  <div className="manage-versions-info-divs">
                    <p className="manage-versions-info-titles">Uploaded</p>
                    <p className="manage-versions-info-details">3/21/26</p>
                  </div>
                  <div className="manage-versions-version-actions">
                    <p className="manage-versions-info-titles">Actions</p>
                    <div className="manage-versions-action-buttons-container">
                      <button
                        onClick={() =>
                          setActiveIndex(activeIndex !== idx ? idx : null)
                        }
                      >
                        Rename Version
                      </button>
                      <button>Create Copy</button>
                      <button className="manage-versions-delete-button">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                {activeIndex === idx && (
                  <div className="manage-versions-rename-section">
                    <label>Edit Name</label>
                    <div>
                      <input type="text" />
                      <button>Apply</button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}

          {/* Toggle Section */}
          <div className="manage-versions-toggle-container">
            <div className="manage-versions-toggle-section">
              <label>
                <IOSSwitch />
                Automatically Managed Version Naming
              </label>
            </div>

            {/* Save Buttons */}
            <div className="manage-versions-save-buttons">
              <button
                className="manage-versions-cancel-btn"
                onClick={ 
                  props.toggleVersionModal
                }
              >
                Cancel
              </button>
              <button className="manage-versions-save-btn">Save</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ViewVersionsPopupComponent;
