import { ANALYTICS_API_URL, API_URL, API_VERSION, STORAGE_KEY } from '../config';
import axios from 'axios';
import SessionUtils from '../utils/sessionUtils';
import Cookies from 'universal-cookie';

/**
 * @class SessionApi
 */
class SessionApi {

  /**
   * User Login
   * @param {string} username 
   * @param {string} password 
   * @returns 
   */
  static login(email, password) {
    const axiosBody = {
      email,
      password
    }
    return axios.post(`${API_URL}/auth/user/login/`, axiosBody);
  }

  /**
   * User Logout
   * @param {string} access_token 
   * @param {string} refresh_token 
   * @returns 
   */
  static logout(access_token, refresh_token) {
    // Integrate logout API
  }

  /**
   * Get new access token by using refresh token
   * @returns 
   */
  static getRefreshToken() {
    const token = SessionUtils.getToken(STORAGE_KEY.access_token);
    const refresh = SessionUtils.getToken(STORAGE_KEY.refresh_token);
    const axiosOptions = {
      headers: {
        'Authorization': token
      }
    };
    return axios.post(`${API_URL}/api/token/refresh/`, { refresh }, axiosOptions);
  }

  static loginAnalytics(username, password) {
    const axiosBody = {
      username,
      password,
      provider: 'db',
      refresh: true
    }
    axios.post(`${ANALYTICS_API_URL}/api/v1/security/login`, axiosBody).then(({ data }) => {
      const axiosOptions = {
        headers: {
          'Authorization': `Bearer ${data.access_token}`,
          'credentials': 'include'
        }
      };
      // axios.get(`https://dev.bigbluecloud.ai/analytics/api/v1/security/csrf_token/`, axiosOptions).then(resp => {
      //   console.log(resp.headers);

      //   // const cookies = new Cookies();
      //   // cookies.set('session', 'Pacman', { path: '/' });
      //   // console.log(cookies.get('myCat'));
      // });
    })


  }
}

export default SessionApi;
