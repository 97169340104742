import React from 'react';
import { ImgCrossRounded } from '../../images';
import '../../styles/hgButtonComponent.scss';

const BackToMainComponent = (props) => {
    const { onclickHandler } = props;
    return (
        <div className='back-to-main'><img src={ImgCrossRounded} onClick={onclickHandler} /></div>
    )
}

export default BackToMainComponent;