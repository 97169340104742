import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { DummyImgUploadDataset, ImgCross, ImgDelete, ImgDownload, ImgExpand, ImgMoreHoriz, ImgOMTLogo, ImgShare, Logo } from '../images';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as datasetActions from '../actions/datasetActions';
import HeaderComponent from './pure/headerComponent';
import '../styles/myDatasetsComponent.scss';
import '../styles/dsgSearchComponent.scss';
import HgDropdownComponent from './pure/hgDropdownComponent';
import HgToolTipComponent from './pure/hgTooltipComponent';
import moment from 'moment/moment';
import { Collapse } from 'reactstrap';
import FormValidationErrorContainer from './pure/formValidationErrorContainer';
import { FILE_TYPES, FORM_VALIDATION, SELECTION_VALIDATION, SIDEBAR_TABS, TOOLTIP_CONFIG, VISIBILITY_TYPE } from '../config';
import HgButtonComponent from './pure/hgButtonComponent';
import { CommonUtils } from '../utils/commonUtils';
import Loader from './loaderComponent';
import HgTagsComponent from './pure/hgTagsComponent';
import RichTextEditor from './pure/richTextEditorComponent';
import axios from 'axios';
import SidebarComponent from './pure/sidebarComponent';
import { getFileSize } from '../utils/numberUtils';
import DatasetApi from '../api/datasetApi';
import BackToMainComponent from './pure/backToMainComponent';
import { getFileFormat } from '../utils/utils';

class AddDatasetComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            titleValid: true,
            titleErrorMessage: 'Enter dataset title',
            description: '',
            descriptionValid: true,
            descriptionErrorMessage: 'Enter dataset description',
            visibility: '',
            visibilityValid: true,
            visibilityErrorMessage: 'Select visibility',
            url: '',
            urlValid: true,
            urlErrorMessage: 'Please upload dataset',
            datasetFormat: '',
            thumbnailImage: '',
            thumbnailUrl: '',
            editThumbnail: false,
            editImageThumbnail: false,
            thumbnailUrlValid: true,
            thumbnailUrlErrorMessage: 'Please upload thumbnail',
            tags: [],
            tagsValid: true,
            tagsErrorMessage: 'Add tags for the dataset',
            contentTypeDetail: {},
            updaloadPercentage: 0,
            contentUploading: false,
            contentUploaded: false,
            contentUuid: '',
            thumbnailUploadPercentage: 0,
            thumbnailUploading: false,
            thumbnailUploaded: false,
            thumbnailFileSizeError: false,
            contentFileSizeError: false,
            checksum: '',
            calChecksum: false,
            contentFileName: '',
            contentFileSize: '',
            thumbnailFileName: '',
            thumbnailFileSize: '',
            thumbnailValid: true,
            selectionValid: true,
            selectionError: '',
            isEditDataset: false

        }
        this.selectedContentFile = null;
        this.selectedThumbnailFile = null;
        this.selectContentRef = createRef();
        this.selectThumbnailRef = createRef();
        this.CancelToken = axios.CancelToken;
        this.source = this.CancelToken.source();
    }

    componentDidMount() {
        const { uid } = this.props.match.params;
        if (uid) {
            const { name, visibility } = this.props.dataset.datasetInfo;
            this.setState({isEditDataset: true , title: name, titleValid: true, visibility: visibility.toLowerCase(), visibilityValid: true});
            this.props.actions.getRootDatasetInfo(uid);
        }
        
    }

    componentWillUnmount() {
        if (this.state.isEditDataset) {
            // this.props.actions.getRootDatasetInfoReset();
        }
    }

    /**
     * Form field validation check
     * @param {string} fieldName 
     * @returns boolean
     */
    validateField = (fieldName) => {
        let fieldValid;
        switch (fieldName) {
            case 'title':
                fieldValid = FORM_VALIDATION.datasetTitle.test(this.state[fieldName]);
                break;
            case 'description':
                fieldValid = true;
                break;
            case 'tags':
                fieldValid = this.state[fieldName].length ? true : false;
                break;
            case 'visibility':
            case 'url':
            case 'datasetFormat':
                fieldValid = this.state[fieldName] ? true : false;
                break;
            default:
                break;
        }
        this.setState({
            [fieldName + 'Valid']: fieldValid
        });
        return fieldValid;
    }

    /**
     * onChange handler
     * @param {InputEvent} e 
     */
    onChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.validateField(name, value));
    }

    /**
     * Select file fron disk
     * @param {e} event 
     * @param {boolean} isThumbnail 
     */
    readFile = (event, isThumbnail = false) => {
        const file = event.target.files[0];
        const fileSize = file.size / (1024 * 1024 * 1024);
        this.setState({ contentFileName: file.name, contentFileSize: getFileSize(file.size) })
        this.selectedContentFile = event.target.files[0];
    }

    /**
     * @function onFileDrop
     * @param {Event} ev 
     * @param {boolean} isThumbnail 
     */
    onFileDrop = (ev, isThumbnail = false) => {
        ev.preventDefault();
        if (ev.dataTransfer.items && (!this.selectedContentFile || (isThumbnail && !this.selectedThumbnailFile))) {
            // Use DataTransferItemList interface to access the file(s)
            for (const item of ev.dataTransfer.items) {
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    if (this.validateOnFileDrop(file, isThumbnail)
                        && ((isThumbnail && this.state.contentUploaded) || !isThumbnail)) {
                        this.readFile({ target: { files: [file] } }, isThumbnail);
                    }
                }
                break;
            }
        } else {
            for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
            }
        }
    }

    /**
     * @function validateOnFileDrop
     * @param {File} file 
     * @param {boolean} isThumbnail 
     * @returns boolean
     */
    validateOnFileDrop = (file, isThumbnail = false) => {
        console.log(file);
        if (!isThumbnail) {
            try {
                const fileContentType = FILE_TYPES.find(item => {
                    if (file.type.toLowerCase() === item.mimeType) {
                        return item;
                    } else if (item.mimeType === 'image/*' && file.type.split('/')[0] === 'image') {
                        return item;
                    } else {
                        return false
                    }
                });
                if (fileContentType) {
                    this.setState({ contentTypeDetail: fileContentType, contentType: fileContentType.value });
                }
                return fileContentType ? true : false;
            } catch (e) {
                console.log("Exception occured in file drop: ", e);
                return false;
            }
        } else if (this.state.contentUploaded) {
            try {
                if (file.type.split('/')[0] === 'image') {
                    this.setState({ thumbnailValid: true });
                }
                else {
                    this.setState({
                        thumbnailValid: false,
                        selectionError: 'Please select image file, supported formats - (.jpg, .png, .gif, etc)'
                    });
                }
                return file.type.split('/')[0] === 'image';
            } catch (e) {
                console.log("Exception occured in thumbnail image drop: ", e);
                return false;
            }
        }
    }

    /**
     * @function validateOnFileSelect
     * @param {Event} ev 
     * @param {boolean} isThumbnail 
     */
    validateOnFileSelect = (ev) => {
        const file = ev.target.files[0];
        console.log(file.type);

        const selectedFileType = FILE_TYPES.find(item => item.mimeType === file.type);

        // const fileType = file.type.split('/')[0] === "image" ? "image/*" : file.type;
        this.setState({ selectionValid: true });
        this.readFile(ev);
    }

    /**
     * Upload file to storage server
     * @param {File} file 
     * @param {boolean} isThumbnail 
     */
    uploadFile = (file, isThumbnail = false) => {
        const fileExtension = getFileFormat(file.name);
        const reader = new FileReader();
        const options = {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: (e) => this.uploadProgress(e, isThumbnail),
            cancelToken: this.source.token
        };

        this.setState({ contentUploading: true, datasetFormat: fileExtension });
        reader.onloadend = (event) => {
            const fileData = event.target.result;
            DatasetApi.getPresignedUrlToUpload(fileExtension).then(({ data }) => {
                const { presigned_url, dataset_S3_url } = data;

                axios.put(presigned_url, fileData, options).then(resp => {
                    console.log(resp);
                    if (resp.status = '200') {
                        this.setState({
                            contentUploaded: true,
                            contentUploading: false,
                            url: dataset_S3_url,
                            urlValid: true,
                        });
                    }
                });
            });
        }

        reader.readAsArrayBuffer(file);
    }

    /**
     * @function uploadProgress
     * @param {ProgressEvent} e 
     * @param {*} isThumbnail 
     */
    uploadProgress = (e, isThumbnail) => {
        if (isThumbnail) {
            this.setState({ thumbnailUploadPercentage: Math.round((e.loaded * 100) / e.total) });
        } else {
            this.setState({ updaloadPercentage: Math.round((e.loaded * 100) / e.total) });
        }
    }

    /**
     * @function cancelUpload
     * @param {boolean} isThumbnail 
     */
    cancelUpload = (isThumbnail) => {
        this.source.cancel();
        this.source = this.CancelToken.source();
        if (isThumbnail) {
            this.selectedThumbnailFile = null;
            this.setState({
                thumbnailUploaded: false,
                thumbnailUploading: false,
                thumbnailFileName: '',
                thumbnailFileSize: 0,
                thumbnailUrl: '',
                thumbnailUploadPercentage: 0
            });
        } else {
            this.selectedContentFile = null;
            this.selectedThumbnailFile = null;
            this.setState({
                contentUploaded: false,
                contentUploading: false,
                contentFileName: '',
                contentFileSize: 0,
                url: '',
                updaloadPercentage: 0,
                thumbnailUploaded: false,
                thumbnailUploading: false,
                thumbnailFileName: '',
                thumbnailFileSize: 0,
                thumbnailUrl: '',
                thumbnailUploadPercentage: 0
            });
        }
    }

    /**
     * @function validateForm
     * @returns boolean - form state valid/invalid (true/false)
     */
    validateForm = () => {
        this.validateField('title');
        this.validateField('description');
        this.validateField('tags');
        this.validateField('visibility');
        this.validateField('datasetFormat');
        this.validateField('url');
        if (this.validateField('title')
            && this.validateField('description')
            && this.validateField('tags')
            && this.validateField('url')
            && this.validateField('visibility')
            && this.validateField('datasetFormat')) {
            return true;
        }
        return false;
    }

    /**
     * @function addEditDataset
     */
    addEditDataset = () => {
        if (this.state.isEditDataset) {
            this.validateField('title');
            this.validateField('description');
            this.validateField('tags');
            this.validateField('visibility');
            if (
                this.validateField('title')
                && this.validateField('description')
                && this.validateField('tags')
                && this.validateField('visibility')
            ) {
                const { title,
                    description,
                    visibility,
                    tags } = this.state;
                this.props.actions.updateRootDatasetInfo(title, visibility, description, tags, this.props.match.params.uid);
            }
            
        } else {
            if (this.validateForm()) {
                const { title,
                    description,
                    url,
                    visibility,
                    datasetFormat,
                    tags } = this.state;
                this.props.actions.addDataset(title, url, visibility, datasetFormat, description, tags);
                }
        }
    }

    /**
     * @function onVisibilitySelect
     * @param {object} item 
     */
    onVisibilitySelect = (item) => {
        this.setState({
            visibility: item.value
        });
    }

    /**
     * @function onTagsUpdate
     * @param {Array} tagsList 
     */
    onTagsUpdate = (tagsList) => {
        this.setState({ tags: tagsList, tagsValid: tagsList.length ? true : false })
    }

    /**
     * @function onDescriptionChange
     * @param {string} data 
     */
    onDescriptionChange = (data) => {
        this.setState({ description: data.trim(), descriptionValid: data.trim().length ? true : false });
    }

    /**
     * @function onDeleteSelectedFile
     * @param {boolean} isThumbnail 
     */
    onDeleteSelectedFile = (isThumbnail = false) => {
        if (isThumbnail) {
            this.selectedThumbnailFile = null;
            this.setState({ thumbnailFileName: '', thumbnailImage: '', thumbnailFileSize: 0, editImageThumbnail: false });
        } else {
            this.selectedContentFile = null;
            this.setState({ contentFileName: '', contentFileSize: 0 });
        }
    }

    handleEdit = () => {
        this.setState({ editThumbnail: true });
    }

    handleCropSave = (canvas) => {
        const image = canvas.toDataURL();
        canvas.toBlob((blob) => {
            const blobFile = new File([blob], this.state.thumbnailFileName, {
                type: this.selectedThumbnailFile.type,
            });
            this.selectedThumbnailFile = blobFile;
            this.setState({
                thumbnailImage: image,
                editThumbnail: false,
                thumbnailFileSize: getFileSize(blob.size)
            });
        }, this.selectedThumbnailFile.type, 0.90);
    }

    handleCancelCrop = () => {
        if (this.state.contentType === 'IMAGE') {
            this.onDeleteSelectedFile();
        }
        this.onDeleteSelectedFile(true);
        this.setState({ editThumbnail: false });
    }

    handleUpload = () => {
        if (this.state.contentType === 'IMAGE' && !this.state.contentUploaded) {
            this.uploadFile(this.selectedContentFile);
        }
        else {
            this.uploadFile(this.selectedThumbnailFile, true);
        }
    }

    render() {
        return (
            <div className='my-datasets-component'>
                <HeaderComponent />
                <SidebarComponent activeTab={SIDEBAR_TABS.myDataSet} />
                <div className='main'>
                    <section className='section-1'>
                        <div className='heading'>
                            <div className='primary'>datasets</div>
                            <div className='secondary'>{this.state.isEditDataset ? 'edit dataset' : 'add dataset'}</div>
                        </div>
                        <BackToMainComponent onclickHandler={() => this.props.history.goBack()} />
                    </section>
                    <section className='add-dataset'>
                        <div className="add-title dsg-form">
                            <div className="form-field">
                                <input type="text"
                                    className="form-control"
                                    name="title"
                                    placeholder="Add a title"
                                    onChange={this.onChange}
                                    onBlur={this.onChange}
                                    defaultValue={this.state.isEditDataset ?  this.props.dataset.datasetInfo.name : ''}
                                />
                                {!this.state.titleValid && <FormValidationErrorContainer errMessage={this.state.titleErrorMessage} />}
                                {/* <HgToolTipComponent text={TOOLTIP_CONFIG.content.title} /> */}
                            </div>
                            <div className="form-field">
                                <HgDropdownComponent
                                    listItems={VISIBILITY_TYPE}
                                    placeholder={"Select Visibilty"}
                                    customListComponent={this.selectContentTypeList}
                                    onSelectHandler={this.onVisibilitySelect}
                                    defaultValue={this.state.isEditDataset ?  this.props.dataset.datasetInfo.visibility.toLowerCase() : ''}
                                />
                                {/* <HgToolTipComponent text={TOOLTIP_CONFIG.content.contentType} /> */}
                                {!this.state.visibilityValid && <FormValidationErrorContainer errMessage={this.state.visibilityErrorMessage} />}
                            </div>
                            {!this.state.isEditDataset &&
                                <><div className="form-field">
                                    <div className="select-content-box" onDrop={this.onFileDrop} onDragOver={e => e.preventDefault()}>
                                        {!this.selectedContentFile
                                            ? <>
                                                <div className="box-title">Drag & Drop Dataset to Upload</div>
                                                <div className="content-types">
                                                </div>
                                                <div className="helper-text">CSV / JSON / TEXT / HDF5</div>
                                                <HgButtonComponent buttonText="Select from computer" onclickHandler={() => this.selectContentRef.current.click()} />
                                            </>
                                            : <div className="selected-content">
                                                <div className={this.state.contentFileSizeError || (this.props.dataset.contentAddError && this.state.calChecksum) ? "file-name error" : "file-name"}>File: {this.state.contentFileName}</div>
                                                <div className="file-size">File size: {this.state.contentFileSize}</div>
                                                {!this.state.contentUploading && !this.state.contentUploaded && <div className="select-file-actions">
                                                    <HgButtonComponent buttonText={"Delete"} onclickHandler={() => this.onDeleteSelectedFile()} />
                                                    <HgButtonComponent buttonText={"Upload file"} isPrimary={true} onclickHandler={() => this.uploadFile(this.selectedContentFile)} />
                                                </div>}
                                                {(this.state.contentUploading || this.state.contentUploaded)
                                                    && <>
                                                        <div className="upload-status">{this.state.updaloadPercentage}% uploaded</div>
                                                        <div className="progress-bar">
                                                            <div className="bar-status" style={{ width: `${this.state.updaloadPercentage}%` }}></div>
                                                        </div>
                                                        <div className="cancel-upload">
                                                            {this.state.contentUploading && <HgButtonComponent buttonText={"Cancel"} onclickHandler={() => this.cancelUpload(false)} />}
                                                            {this.state.contentUploaded && <HgButtonComponent buttonText={"Remove"} onclickHandler={() => this.cancelUpload(false)} />}
                                                        </div>
                                                    </>}
                                            </div>
                                        }
                                        <input ref={this.selectContentRef} type="file" accept={this.state.contentTypeDetail.mimeType}
                                            onChange={this.validateOnFileSelect}
                                            onClick={(event) => {
                                                event.target.value = null
                                            }}
                                            hidden
                                        />
                                    </div>
                                    {!this.state.selectionValid && <FormValidationErrorContainer errMessage={this.state.selectionError} />}
                                    {!this.state.urlValid && <FormValidationErrorContainer errMessage={this.state.urlErrorMessage} />}
                                </div>
                                    {!this.state.thumbnailValid && <FormValidationErrorContainer errMessage={this.state.selectionError} />}
                                </>}
                        </div>
                        <div className="keywords">
                            <div className="section-title">keywords
                                <HgToolTipComponent text={TOOLTIP_CONFIG.content.keywords} /></div>
                            <HgTagsComponent tagsChangeHandler={this.onTagsUpdate} defaultTags={this.state.isEditDataset ? this.props.dataset.datasetInfo.tags : ''} />
                            {!this.state.tagsValid && <FormValidationErrorContainer errMessage={this.state.tagsErrorMessage} />}
                        </div>
                        <div className="description">
                            <div className="section-title">description
                                <HgToolTipComponent text={TOOLTIP_CONFIG.content.description} />
                            </div>
                            <div className="description-box">
                                <RichTextEditor onTextChange={this.onDescriptionChange} defaultText={this.state.isEditDataset ? this.props.dataset.datasetInfo.description : ''} />
                            </div>
                            {!this.state.descriptionValid && <FormValidationErrorContainer errMessage={this.state.descriptionErrorMessage} />}
                        </div>
                        <div className="form-actions">
                            {this.props.dataset.isLoading
                                ? <Loader customClass={'sm'} />
                                : <HgButtonComponent buttonText={"Save"} isPrimary={true} onclickHandler={this.addEditDataset} disabled={this.props.dataset.isLoading} />}
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        dataset: state.dataset
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(datasetActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDatasetComponent);