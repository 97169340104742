import React, { useState } from 'react';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  position: absolute;
  display: inline-block;
  width: 213px;
  height: 100%;
  border-right: 1px solid 
  $big-blue-ref-neutral-variant-neutral-variant-95;
  // cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: visible;  
  z-index:10;       
`;

const TooltipText = styled.div`
  visibility: visible;
  overflow: visible;
  border-radius: 4px;
  gap: 4px;
  background-color:  #2F3033;
  color: #F0F0F4;
  text-align: center;
 
  padding: 5px;
  position: absolute;
  top: 70%; 
  left: 30%;
  font-family:Space Mono;
  font-weight:400;
  font-size:12px;
  line-height:20px,
  
  opacity: 1;
   
 
  transition: opacity 0.3s;
 
  &::after {
    content: '';
    position: absolute;
     transform: translateX(-50%);
    left: 50%;
    gap:4px;
    border-width: 5px;
    border-style: solid;
    border-color: #2F3033 transparent transparent transparent;
    border-radius: 4px;
  }
`;

const CustomTooltip = ({ children,content }) => {
  const [visible, setVisible] = useState(false);

  return (
    <TooltipContainer
    onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && <TooltipText> {content}</TooltipText>}
    </TooltipContainer>
  );
};

export default CustomTooltip;
