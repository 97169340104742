import React from 'react';
import { ImgHelp, ImgInfo, ImgTooltipPointer } from '../../images';
import '../../styles/hgTooltipComponent.scss';

const HgToolTipComponent = (props) => {
    const { text } = props;
    return (
        <div className="hg-tooltip">
            <span className="tooltip-handler"><img src={ImgHelp} width="16px" /></span>
            <div className="tooltip-container">
                {text}
                <img src={ImgTooltipPointer} />
            </div>
        </div>
    )
}

export default HgToolTipComponent;