import { SESSION_ACTIONS } from '../actions/actionTypes';
import sessionUtils from '../utils/sessionUtils';

const initialState = {
  loggedIn: !!sessionUtils.getToken('refresh_token'),
  loginErrorMessage: '',
  isLoading: false,
  loginSuccess: false
};

export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case SESSION_ACTIONS.LOGIN_LOADING:
      return { ...state, isLoading: true, loginErrorMessage: '' };
    case SESSION_ACTIONS.LOGIN_SUCCESS:
      return { ...state, loggedIn: !!sessionUtils.getToken('refresh_token'), loginErrorMessage: '', isLoading: false, loginSuccess: true };
    case SESSION_ACTIONS.LOGIN_FAILURE:
      return { ...state, loginErrorMessage: action.loginErrorMessage, isLoading: false, loginSuccess: false };
    case SESSION_ACTIONS.LOGIN_RESET:
      return initialState;
    case SESSION_ACTIONS.LOGOUT:
      return { ...state, loggedIn: !!sessionUtils.getToken('refresh_token'), loginErrorMessage: '' };
    case SESSION_ACTIONS.LOGOUT:
      return initialState;
    default:
      return state;
  }
}
