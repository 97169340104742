import "../styles/viewLicensePopup.scss";
import React, { useEffect, useRef, useState } from "react";
import "../styles/manageLicense.scss";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import LicenseComponent from "./licenseComponent";
import Checkbox from "@mui/material/Checkbox";
import { ImgArrowDown, ImgCross, ImgCrossRounded } from "../images";
import { Box, Typography } from "@mui/material";
import HgDropdownComponent from "./pure/hgDropdownComponent";
import { getFileFormat } from "../utils/utils";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
const ViewLicensePopupComponent = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 50,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "rgba(0, 99, 154, 1)",
          opacity: 1,
          border: 0,
          ...theme.applyStyles("dark", {
            backgroundColor: "rgba(0, 99, 154, 1)",
          }),
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
        ...theme.applyStyles("dark", {
          color: theme.palette.grey[600],
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
        ...theme.applyStyles("dark", {
          opacity: 0.3,
        }),
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      ...theme.applyStyles("dark", {
        backgroundColor: "#39393D",
      }),
    },
  }));
  // const [licenseDropdownDefaultValue, setLicenseDropdownDefaultValue] =
  //   useState("");
  const openSourceLicenseList = [
    "GNU General Public License v3.0",
    "GNU General Public License v2.0",
    "Creative Commons Zero v1.0 Universal",
    "BSD 2-Clause Simplified License",
    "BSD 3-Clause New or Revised License",
    "The Unlicense",
    "Upload Open Source License",
  ];
  const proprietaryList = ["Upload Proprietary License"];

  const [selectedFile, setSelectedFile] = useState(null);
  const [restrictSharePublic, setRestrictSharePublic] = useState(true);
  const [restrictShareOrg, setRestrictShareOrg] = useState(false);
  const [selectedCard, setSelectedCard] = useState("open-source");
  const [dropdownDefaultValue, setLicenseDropdownDefaultValue] = useState(
    openSourceLicenseList[0]
  );

  // const handleUpdate = () => {
  //   //bring the file url
  //   const fileExtension = getFileFormat(selectedFile.name);
  //   //then make data
  //   const data = {
  //     dataset_root_uid: props.id,
  //     dataset_child_uid: props.childID,
  //     name: props.name,
  //     apply_license: licenseDropdownDefaultValue === "Apply to All Versions"?"ALL":"THIS",
  //     restrict_share_public: restrictSharePublic,
  //     restrict_share_org: restrictShareOrg,
  //     license_url: licenseUrl,
  //     license_slug: licenseSlug,
  //   };
  //   //then send data to api call
  // };

  const uploadTextRef = useRef();

  // const applyTypeList = ["Apply to This Version Only", "Apply to All Versions"];

  const [openSourceType, setOpenSourceType] = useState(
    openSourceLicenseList[0]
  );

  const selectApplyType = (value) => {
    setOpenSourceType(value.value);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpdateClick = () => {
    uploadTextRef.current.click();
  };
  useEffect(() => {
    if (selectedCard === "open-source") {
      setLicenseDropdownDefaultValue(openSourceLicenseList[0]);
    } else setLicenseDropdownDefaultValue(proprietaryList[0]);
  }, [selectedCard]);
  return (
    <Modal
      className="manage-license-modal"
      isOpen={props.licenseOpen}
      toggle={props.toggleLicenseModal}
    >
      <div className="manage-license-container">
        <div className="manage-license-title-cross-img">
          <p className="manage-license-title">Manage license</p>
          <p className="manage-license-cross-img">
            <img src={ImgCross} onClick={props.toggleLicenseModal} />
          </p>
        </div>

        <div className="manage-license-dataset-info">
          <p className="manage-license-dataset-title">Dataset Title</p>
          <p className="manage-license-dataset-name">{props.name}</p>
        </div>
        {/* <div className="manage-license-apply-container">
          <p className="manage-license-apply-heading">Apply</p>
          <p className="manage-license-apply-content">
            <HgDropdownComponent
              placeholder="Apply to This Version Only"
              listItems={applyTypeList}
              defaultValue={licenseDropdownDefaultValue}
              listIdentifier="manage-license-dropdown"
              enableSearch={false}
              onSelectHandler={selectApplyType}
              customClass={"manage-license-dropdown"}
            />
          </p>
        </div> */}
        <div className="license-type-card-container">
          <p>
            Choose the type of license you will use{" "}
            <p className="unsure-section">(unsure?)</p>
          </p>
          <div className="license-cards">
            <div
              className={`btn open-source license-card ${
                selectedCard === "open-source" ? "blue" : ""
              }`}
              onClick={() => setSelectedCard("open-source")}
            >
              <p className="card-title">Open Source License</p>
              <p>Choose from available Open Source Licenses</p>
            </div>
            <div
              className={`btn proprietary license-card ${
                selectedCard === "proprietary" ? "blue" : ""
              }`}
              onClick={() => setSelectedCard("proprietary")}
            >
              <p className="card-title">Proprietary License</p>
              <p>I have a Proprietary License to Upload</p>
            </div>
          </div>
        </div>
        <div className="manage-license-dataset-license-container">
          <p className="manage-license-heading">Data License</p>
          <p className="manage-license-content">
            {selectedCard === "open-source" && (
              <HgDropdownComponent
                placeholder={openSourceLicenseList[0]}
                listItems={openSourceLicenseList}
                defaultValue={dropdownDefaultValue}
                listIdentifier="manage-license-dropdown"
                enableSearch={false}
                onSelectHandler={selectApplyType}
                customClass={"manage-license-dropdown"}
              />
            )}
            {selectedCard === "proprietary" && (
              <HgDropdownComponent
                placeholder={proprietaryList[0]}
                listItems={proprietaryList}
                defaultValue={dropdownDefaultValue}
                listIdentifier="manage-license-dropdown"
                enableSearch={false}
                onSelectHandler={selectApplyType}
                customClass={"manage-license-dropdown"}
              />
            )}
          </p>
        </div>
        <label className="switch-container">
          <IOSSwitch />
          <p>Apply to this version only</p>
        </label>
        {(selectedCard === "proprietary" ||
          openSourceType === "Upload Open Source License") && (
          <>
            <button
              onClick={handleUpdateClick}
              className="manage-license-upload-button"
            >
              Upload License Text File
            </button>

            <input
              type="file"
              ref={uploadTextRef}
              id="fileInput"
              onChange={handleFileChange}
              hidden
            />
            <div className="manage-license-file-name-section">
              FILE NAME:{" "}
              {selectedFile && (
                <span className="manage-license-file-name">
                  {selectedFile.name}
                </span>
              )}
            </div>
          </>
        )}

        <div className="manage-license-view-license-button-container">
          <button
            onClick={toggle}
            className="manage-license-view-license-button"
          >
            View License File
          </button>
        </div>
        <div className="manage-license-sharing-section">
          <div className="manage-license-sharing-title">LICENSE SHARING</div>
          <div className="manage-license-option-1">
            <Checkbox defaultChecked />
            Restrict sharing to only individuals in My Organization
          </div>
          <div className="manage-license-option-2">
            <Checkbox />
            Restrict publication of dataset to Public Datasets
          </div>
        </div>
        <div className="manage-license-update-cancel-section">
          <button
            className="manage-license-cancel-btn"
            onClick={props.toggleLicenseModal}
          >
            Cancel
          </button>
          <button className="manage-license-update-btn">Update</button>
        </div>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        style={{
          width: "540px",
          height: "fit-content",
          marginLeft: "40%",
        }}
        className={"modal-container"}
      >
        <ModalBody
          style={{
            width: "600px",
            height: "fit-content",
            margin: "0",
            display: "flex",
            flexDirection: "column",
            padding: "1em",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3)",
          }}
        >
          <div>
            <button style={{ border: "none", padding: "0" }} onClick={toggle}>
              <img
                style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}
                src={ImgCrossRounded}
              />
            </button>
          </div>
          <LicenseComponent />
        </ModalBody>
      </Modal>
    </Modal>
  );
};

export default ViewLicensePopupComponent;
