
import { STORAGE_KEY } from '../config';
import { localStorageGetItem } from './localStorageUtils';
const storageTypes = {
    localStorage,
    sessionStorage
};

/**
 * @class SessionUtils
 */
class SessionUtils {

    /**
     * @function saveToken
     * @param {string} key 
     * @param {string} token 
     * @param {string} expires 
     */
    static saveToken(key, token, expires) {
        const item = {
            token,
            expires
        }
        let storage = storageTypes.localStorage;
        if (localStorageGetItem(STORAGE_KEY.rememberMe) !== 'true') {
            storage = storageTypes.sessionStorage;
        }
        storage.setItem(key, JSON.stringify(item));
    }

    /**
     * @function getToken
     * @param {string} key 
     * @returns auth token
     */
    static getToken(key) {
        let storage = storageTypes.localStorage;
        if (localStorageGetItem(STORAGE_KEY.rememberMe) !== 'true') {
            storage = storageTypes.sessionStorage;
        }
        if (!storage.getItem(key)) {
            return null;
        }
        const { token } = JSON.parse(storage.getItem(key));
        if (!token) {
            return null;
        }
        return token;
    }

    /**
     * @function flushToken
     * @param {string} key 
     */
    static flushToken(key) {
        let storage = storageTypes.localStorage;
        if (localStorageGetItem(STORAGE_KEY.rememberMe) !== 'true') {
            storage = storageTypes.sessionStorage;
        }
        storage.getItem(key) && storage.removeItem(key);
    }
}

export { SessionUtils };
export default SessionUtils;