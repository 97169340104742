import React from "react";
import "../../styles/gridViewDatasetComponent.scss";

export const colors = {
  IMAGERY:
    "radial-gradient(ellipse 68.47% 162.09% at 50% 50%, #DFF9FF 0%, #ADCBEC 100%)",
  MEASUREMENT_DATA:
    "radial-gradient(91.86% 222.6% at 18.63% 50%, #D1D1D1 0%, #A3A7AC 100%)",
  MODEL_OUTPUTS:
    "radial-gradient(91.86% 222.6% at 18.63% 50%, #D9D9D9 0%, #ADCBEC 100%)",
  TEXTUAL_DATA:
    "radial-gradient(62.69% 124.06% at 45.28% 100%, #D3DAEA 0%, #9FBEE1 100%)",
  GEOSPATIAL_DATA:
    "radial-gradient(68.19% 157.3% at 50% 88.44%, #B0D1D3 0%, #8EC5C9 100%)",
};

const GridViewDatasetComponent = ({title , description, payment, license, dataset_format, data_type}) => {
    const datasetBackgroundColor = colors[data_type] || colors.IMAGERY;
    return (
        <div className='grid-dataset-container'>
            <div className='dataset-details'>
                <div className='dataset-img' style={{ background: datasetBackgroundColor }}></div>
                <div className='dataset-info'>
                    <div className='dataset-title'>{title || "Tsunami Simulation Data"}</div>
                    <div className='organisation'>USGS</div>
                </div>
            </div>
            <div className='dataset-description'>{description || "Simulation data for tsunami impact predictions."}</div>
            <div className='data-type'>Data: {dataset_format || "GeoTiff"}</div>
            <div className='license'>License: {license || "Non-Commercial"}</div>
            <button className='btn'>{payment || "Free"}</button>
        </div>
    )
}

export default GridViewDatasetComponent;
