import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { ImgTBBCLogo } from "../images";
import "../styles/categoryViewComponent.scss";
import SearchBarComponent from "./pure/searchBarComponent";
import { GoChevronRight, GoPlus } from "react-icons/go";
import GridViewDatasetComponent from "./pure/gridViewDatasetComponent";
import ListViewDatasetComponent from "./pure/listViewDatasetComponent";
import SelectComponent from "./pure/selectComponent";
import { BsGrid } from "react-icons/bs";
import { HiOutlineViewList } from "react-icons/hi";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOceanDataset, getOceanFilterDataset, setOceanDataFilter } from "../actions/datasetActions";
import Loader from "./loaderComponent";
import SidebarComponent from "./pure/sidebarComponent";
import { SIDEBAR_TABS } from "../config";

export const categoryDetails = {
  imagery: {
    title: "Imagery",
    description:
      "This includes numerical data collected through various instruments, such as sensors on buoys, ARGO floats, or satellite-based measurement systems. It typically involves physical parameters like temperature, salinity, pH, and ocean currents, used for climate monitoring, oceanographic studies, and marine research.",
    background:
      "radial-gradient(ellipse 68.47% 162.09% at 50% 50%, #DFF9FF 0%, #ADCBEC 100%)",
  },
  "measurement-data": {
    title: "Measurement Data",
    description:
      "This includes numerical data collected through various instruments, such as sensors on buoys, ARGO floats, or satellite-based measurement systems. It typically involves physical parameters like temperature, salinity, pH, and ocean currents, used for climate monitoring, oceanographic studies, and marine research.",
    background:
      "radial-gradient(91.86% 222.6% at 18.63% 50%, #D1D1D1 0%, #A3A7AC 100%)",
  },
  "model-outputs": {
    title: "Model Outputs",
    description:
      "This includes numerical data collected through various instruments, such as sensors on buoys, ARGO floats, or satellite-based measurement systems. It typically involves physical parameters like temperature, salinity, pH, and ocean currents, used for climate monitoring, oceanographic studies, and marine research.",
    background:
      "radial-gradient(91.86% 222.6% at 18.63% 50%, #D9D9D9 0%, #ADCBEC 100%)",
  },
  "textual-data": {
    title: "Textual Data",
    description:
      "This includes numerical data collected through various instruments, such as sensors on buoys, ARGO floats, or satellite-based measurement systems. It typically involves physical parameters like temperature, salinity, pH, and ocean currents, used for climate monitoring, oceanographic studies, and marine research.",
    background:
      "radial-gradient(62.69% 124.06% at 45.28% 100%, #D3DAEA 0%, #9FBEE1 100%)",
  },
  "geospatial-data": {
    title: "Geospatial Data",
    description:
      "This includes numerical data collected through various instruments, such as sensors on buoys, ARGO floats, or satellite-based measurement systems. It typically involves physical parameters like temperature, salinity, pH, and ocean currents, used for climate monitoring, oceanographic studies, and marine research.",
    background:
      "radial-gradient(68.19% 157.3% at 50% 88.44%, #B0D1D3 0%, #8EC5C9 100%)",
  },
};

const CategoryViewComponent = () => {
  const [isListView, setIsListView] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { id } = useParams();

  const category = categoryDetails[id];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOceanDataset());
  }, []);

  const {oceanDatasets, isDatasetsListLoading, filterObj} = useSelector((state) => ({
    oceanDatasets: state.dataset.oceanDatasets,
    isDatasetsListLoading: state.dataset.isDatasetsListLoading,
    filterObj: state.dataset.oceanDataFilter,
  }));

  const handleSearch = (searchText) => {

    const updatedFilterObj = {
      ...filterObj,
      ...(searchText ? { search_keywords: [searchText] } : { search_keywords: [] }), 
    };
  
    const paramObj = Object.keys(updatedFilterObj).reduce((acc, key) => {
      if (updatedFilterObj[key].length > 0) {
        acc[key] = updatedFilterObj[key].join(","); 
      }
      return acc;
    }, {});
  
    dispatch(setOceanDataFilter(updatedFilterObj)); 
    dispatch(getOceanFilterDataset(paramObj));
  }

  return (
    <div className="category-view-component">
      <Link to={'/'}>
      <div className="header" style={{paddingLeft: '128px'}}>
        <img className="big-blue-cloud" src={ImgTBBCLogo} alt="LOGO"/>
      </div>
      </Link>
      <SidebarComponent activeTab={SIDEBAR_TABS.oceanDatasets}/>
      <div className="main">
        <div className="ocean-data">Ocean of Data/{category.title}</div>
        <div className="category-view">
          <div className="category-details">
            <div className="data-category">Data Category</div>
            <div className="category-title">{category.title}</div>
            <div className="category-description">{category.description}</div>
          </div>
          <div
            className="category-img"
            style={{ background: category.background }}
          ></div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <button className="filter-btn" onClick={() => setIsFilter(true)}>
              <GoPlus /> Filters
            </button>
            {isFilter && (
              <>
                <div className="backdrop">
                  <div className="filter-popup">
                    <SelectComponent setIsFilter={setIsFilter} />
                  </div>
                </div>
              </>
            )}

            <SearchBarComponent setSearchText={handleSearch}/>
          </div>
          <div style={{ display: "flex", marginBottom: "18px" }}>
            <BsGrid
              onClick={() => setIsListView(false)}
              size={25}
              style={{
                color: `${!isListView ? "#004A75" : "#D5E4F6"}`,
                cursor: "pointer",
                marginRight: "15px",
              }}
            />
            <HiOutlineViewList
              style={{
                color: `${isListView ? "#004A75" : "#D5E4F6"}`,
                cursor: "pointer",
              }}
              onClick={() => setIsListView(true)}
              size={25}
            />
          </div>
        </div>
        <div className="datasets">
          <div className="heading">Popular Datasets</div>
          <div className="view-all">
            <div className="view-all-text">View All</div>
            <div className="next-icon">
              <GoChevronRight />
            </div>
          </div>
        </div>
        {!isListView ? (
          <div className="popular-datasets-grid">
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
          </div>
        ) : (
          <div className="popular-datasets-list">
            <ListViewDatasetComponent />
            <ListViewDatasetComponent />
          </div>
        )}
        {!isListView && <div className="seperation-line"></div>}
        <div className="datasets">
          <div className="heading">All Datasets</div>
          <div className="view-all">
            <div className="view-all-text">View All</div>
            <div className="next-icon">
              <GoChevronRight />
            </div>
          </div>
        </div>
        {isDatasetsListLoading ? (
          <Loader />
        ): (
          <>
          {!isListView ? (
          <div className="all-datasets-grid">
            {oceanDatasets.results.map((item) => 
              (<GridViewDatasetComponent key={item.uid} title={item.name} description={item.description} payment={item.payment} license={item.license} dataset_format={item.dataset_format} data_type={item.data_type}/>))}
          </div>
        ) : (
          <div className="all-datasets-list">
            {oceanDatasets.results.map((item) => (<ListViewDatasetComponent key={item.uid} title={item.name} description={item.description} payment={item.payment} license={item.license} data_type={item.data_type}/>))}
          </div>
        )}
          </>
        )}
        {!isListView && <div className="seperation-line"></div>}
        <div className="datasets">
          <div className="heading">Trending Datasets</div>
          <div className="view-all">
            <div className="view-all-text">View All</div>
            <div className="next-icon">
              <GoChevronRight />
            </div>
          </div>
        </div>
        {!isListView ? (
          <div className="popular-datasets-grid">
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
            <GridViewDatasetComponent />
          </div>
        ) : (
          <div className="popular-datasets-list">
            <ListViewDatasetComponent />
            <ListViewDatasetComponent />
          </div>
        )}
         {!isListView && !isDatasetsListLoading && <div className="seperation-line"></div>}
      </div>
    </div>
  );
};

export default CategoryViewComponent;
