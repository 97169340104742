import { ACCOUNT_ACTIONS, MODEL_ACTIONS } from '../actions/actionTypes';

const initialState = {
    userInfo: {
        username: '',
        email: '',
        userprofile: {
            organization_name: '',
            first_name: '',
            last_name: ''
        }
    },
    isSingupLoading: false,
    isSignupSuccess: false,
    isVerifyEmailLoading: false,
    isVerifyEmailSuccess: false,
    isResetPasswordLoading: false,
    isResetPasswordSuccess: false,
    isChangePasswordLoading: false,
    isChangePasswordSuccess: false,
    isSetResetPasswordLoading: false,
    isSetResetPasswordSuccess: false,
    formError: ""
};

export default function accountReducer(state = initialState, action) {
    switch (action.type) {
        case ACCOUNT_ACTIONS.GET_CUREENT_USER_INFO_FROM_CACHE:
            return { ...state, userInfo: action.payload };
        case ACCOUNT_ACTIONS.SIGNUP_LOADING:
            return { ...state, isSingupLoading: true, formError: "" };
        case ACCOUNT_ACTIONS.SIGNUP_SUCCESS:
            return { ...state, isSignupSuccess: true, isSingupLoading: false, formError: "" };
        case ACCOUNT_ACTIONS.SIGNUP_FAILURE:
            return { ...state, isSingupLoading: false, formError: action.payload };
        case ACCOUNT_ACTIONS.VERIFY_EMAIL_LOADING:
            return { ...state, isVerifyEmailLoading: true };
        case ACCOUNT_ACTIONS.VERIFY_EMAIL_SUCCESS:
            return { ...state, isVerifyEmailSuccess: true, isVerifyEmailLoading: false };
        case ACCOUNT_ACTIONS.VERIFY_EMAIL_ERROR:
            return { ...state, isVerifyEmailSuccess: false, isVerifyEmailLoading: false };
        case ACCOUNT_ACTIONS.RESET_PASSWORD_LOADING:
            return { ...state, isResetPasswordLoading: true, formError: "" };
        case ACCOUNT_ACTIONS.RESET_PASSWORD_SUCCESS:
            return { ...state, isResetPasswordLoading: false, isResetPasswordSuccess: true, formError: "" };
        case ACCOUNT_ACTIONS.RESET_PASSWORD_ERROR:
            return { ...state, isResetPasswordLoading: false, formError: action.payload };
        case ACCOUNT_ACTIONS.CHANGE_PASSWORD_LOADING:
            return { ...state, isChangePasswordLoading: true, formError: "" };
        case ACCOUNT_ACTIONS.CHANGE_PASSWORD_SUCCESS:
            return { ...state, isChangePasswordLoading: false, isChangePasswordSuccess: true, formError: "" };
        case ACCOUNT_ACTIONS.CHANGE_PASSWORD_ERROR:
            return { ...state, isChangePasswordLoading: false, isChangePasswordSuccess: false, formError: action.payload };
        case ACCOUNT_ACTIONS.CHANGE_PASSWORD_RESET:
            return { ...state, isChangePasswordLoading: false, isChangePasswordSuccess: false, formError: "" };
        case ACCOUNT_ACTIONS.SET_RESET_PASSWORD_LOADING:
            return { ...state, isSetResetPasswordLoading: true, formError: "" };
        case ACCOUNT_ACTIONS.SET_RESET_PASSWORD_SUCCESS:
            return { ...state, isSetResetPasswordSuccess: true, isSetResetPasswordLoading: false };
        case ACCOUNT_ACTIONS.SET_RESET_PASSWORD_ERROR:
            return { ...state, isSetResetPasswordSuccess: false, isSetResetPasswordLoading: false, formError: action.payload }
        case ACCOUNT_ACTIONS.ACCOUNT_STATE_RESET:
            return { initialState };
        default:
            return state;
    }
}