import "../styles/licenseComponent.scss"
const LicenseComponent = () => {
  return (
    <div className="license-container">
    
          <div className="license-title">GNU GENERAL PUBLIC LICENSE</div>
          <div className="license-subtitle"> Version 2, June 1991</div>
          <br/>
          <div className="license-content">
            Copyright (C) 1989, 1991 Free Software Foundation, Inc. 51 Franklin
            Street, Fifth Floor, Boston, MA 02110-1301, USA
          </div>{" "}
          <br/>
          <div className="license-content">
            {" "}
            Everyone is permitted to copy and distribute verbatim copies of this
            license document, but changing it is not allowed.
          </div>{" "}
          <div className="license-content">Preamble</div>
          <div className="license-content">
            The licenses for most software are designed to take away your
            freedom to share and change it. By contrast, the GNU General Public
            License is intended to guarantee your freedom to share and change
            free software--to make sure the software is free for all its users.
            This General Public License applies to most of the Free Software
            Foundation's software and to any other program whose authors commit
            to using it. (Some other Free Software Foundation software is
            covered by the GNU Lesser General Public License instead.) You can
            apply it to your programs, too.
          </div>{" "}
          <br/>
          <div className="license-content">
            When we speak of free software, we are referring to freedom, not
            price. Our General Public Licenses are designed to make sure that
            you have the freedom to distribute copies of free software (and
            charge for this service if you wish), that you receive source code
            or can get it if you want it, that you can change the software or
            use pieces of it in new free programs; and that you know you can do
            these things.
          </div>
          <br/>
          <div className="license-content">
            To protect your rights, we need to make restrictions that forbid
            anyone to deny you these rights or to ask you to surrender the
            rights. These restrictions translate to certain responsibilities for
            you if you distribute copies of the software, or if you modify it.
          </div>
      
    </div>
  )
}

export default LicenseComponent
