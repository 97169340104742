import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { ImgArrowRight, ImgPersonOutline } from '../../images';
import ToggleSwtichComponent from './toggleSwitchComponent';
import '../../styles/datasetAccessComponent.scss';
import { useDispatch } from 'react-redux';
import { datasetShareEnableDisble } from '../../actions/datasetActions';

export const DatasetAccessComponent = (props) => {
    const { data, datasetId, onEditAccess } = props;
    const dispatch = useDispatch();
    const [shareViewOpen, setShareViewOpen] = useState(false);

    const updateIsEnabled = (e) => {
        const { uid } = data;
        dispatch(datasetShareEnableDisble(uid, datasetId, e.target.checked));
    }

    const getAccessType = (data) => {
        try {
            if (data.access_type) {
                return data.access_type.charAt(0).toUpperCase() + data.access_type.slice(1).toLowerCase();
            } else {
                return data.is_downloadable ? 'Editor' : 'Viewer'
            }
        } catch {
            return 'Viewer'
        }
    }

    return (
        <div className='access-info'>
            <div style={{display:'flex', justifyContent:'space-between'}}>
          
            <div style={{fontFamily:'Space Mono', fontSize:'400', fontWeight:400, lineHeight:'20px', color:'#1A1C1E'}}>
            {`${data.first_name} ${data.last_name}`}
            </div>
            <div style={{fontFamily:'Space Mono', fontSize:'400', fontWeight:400, lineHeight:'20px', color:'#1A1C1E'}}>
            {getAccessType(data)}
            </div>
            </div>
           
        </div>

        
    )
}

export default DatasetAccessComponent;