import { API_ERRORS, DOCUMENT_ROOT, STORAGE_KEY } from '../config';
import { COMMON_ERROR_MESSAGE, SESSION_ACTIONS } from './actionTypes';
import sessionApi from '../api/sessionApi';
import { push } from 'connected-react-router';
import { localStorageGetItem, localStorageSetItem, localStorageRemoveItem, localStorageSetObject } from '../utils/localStorageUtils';
import { formatErrorMessages } from '../utils/stringUtils.js';
import SessionUtils from '../utils/sessionUtils';
import AccountApi from '../api/accountApi';
import { getCurrentUserInfoFromCache } from './accountActions';

export function login(username, password) {
  return function (dispatch) {
    dispatch(loginLoading());
    sessionApi.login(username, password).then(({data}) => {
      SessionUtils.saveToken(STORAGE_KEY.access_token, `Bearer ${data['tokens']['access']}`);
      SessionUtils.saveToken(STORAGE_KEY.refresh_token, `${data['tokens']['refresh']}`);
      localStorageSetObject(STORAGE_KEY.user_info, data['profile']);
      sessionApi.loginAnalytics(username, password);
      // sessionApi.loginAnalytics('admin', 'admin');
      dispatch(loginSuccess());
      const redirectUrl = DOCUMENT_ROOT + 'my-datasets';
      setTimeout(() => {
        dispatch(push(redirectUrl));
        dispatch(resetLoginForm());
      }, 1800);
      // dispatch(push(redirectUrl));
      // AccountApi.getUserInfo().then(({data}) => {
      //   // localStorageSetObject(STORAGE_KEY.user_info, data);
      //   dispatch(getCurrentUserInfoFromCache());
        
      //   dispatch(loginSuccess());
      //   dispatch(push(redirectUrl));
      // });
    }).catch(err => {
      if (err.response && err.response['status']) {
        switch (err.response['status']) {
          case 400:
            return dispatch(loginFailure(formatErrorMessages('User not registered! To sign up, <click here>.')));
          case 401:
            try {
              return dispatch(loginFailure(err.response.data.detail));
            } catch (e) {
              return dispatch(loginFailure('Email and password don\'t match'));
            }
          case 403:
            return dispatch(loginFailure('Email and password don\'t match'));
          case 417:
            return dispatch(loginFailure(formatErrorMessages('User not activated! To activate account, <click here>.', username)));
          default:
            return dispatch(loginFailure(API_ERRORS[err.response['status']]));
        }
      }
    });
  }
}

export function loginSuccess() {
  return { type: SESSION_ACTIONS.LOGIN_SUCCESS }
}

export function loginFailure(loginErrorMessage) {
  return { type: SESSION_ACTIONS.LOGIN_FAILURE, loginErrorMessage }
}

export function logout() {
  return function (dispatch) {
    const accessToken = SessionUtils.getToken('access_token');
    const refreshToken = SessionUtils.getToken('refresh_token');
    SessionUtils.flushDomainVersion();
    SessionUtils.flushDomain();
    SessionUtils.flushToken('access_token');
    SessionUtils.flushToken('refresh_token');
    SessionUtils.invalidateDomain();
    dispatch(logoutSuccess());
    dispatch(push(DOCUMENT_ROOT));
    // Integate logout API
    sessionApi.logout(accessToken, refreshToken);
  }
}

export function logoutSuccess() {
  return { type: SESSION_ACTIONS.LOGOUT }
}

export function getUniqueId(idType, username, callback) {
  let fetchDeviceUUId = idType === 'uuid';
  let virtualSerialId = localStorageGetItem(STORAGE_KEY.virtualSerialId);
  if (!virtualSerialId) {
    sessionApi.getVirtualSerialId().then(response => {
      if (!fetchDeviceUUId) {
        callback(null, response.data.virtualserialID);
      } else {
        getDeviceUUId(response.data.virtualserialID, username, callback);
      }
      localStorageSetItem(STORAGE_KEY.virtualSerialId, response.data.virtualserialID);
    }).catch(error => {
      callback(error);
    });
  } else if (!fetchDeviceUUId) {
    callback(null, virtualSerialId);
  } else {
    getDeviceUUId(virtualSerialId, username, callback);
  }
}

export function getDeviceUUId(virtualSerialId, username, callback) {
  sessionApi.getDeviceUUId(virtualSerialId, username).then(response => {
    callback(null, response.data.deviceUUID);
  }).catch(err => {
    try {
      if (err.response.data.code === 26) {
        localStorageRemoveItem(STORAGE_KEY.virtualSerialId);
        sessionApi.getVirtualSerialId().then(response => {
          localStorageSetItem(STORAGE_KEY.virtualSerialId, response.data.virtualserialID);
          getDeviceUUId(response.data.virtualserialID, username, callback)
        }).catch(error => {
          callback(error);
        });
      } else {
        callback(err);
      }
    } catch (e) {
      console.log(e, err);
      callback(err);
    }
  });
}

export function loginLoading() {
  return { type: SESSION_ACTIONS.LOGIN_LOADING }
}

export function getDomainObject(callback) {
  sessionApi.getDomainObject().then(response => {
    // Store the header for domain object comparison
    SessionUtils.setDomainVersion(response.headers['domainversion']);
    // Domain object
    SessionUtils.setDomain(response.data['domain']);
    SessionUtils.domainUpdated();
    callback(null);
  }).catch(error => {
    callback(error);
  });
}

export function confirmAppGeePassword(password, callback) {
  // Get username from domain object
  const domain = SessionUtils.getDomain();
  const username = domain.selfProfile.username;
  getUniqueId('uuid', username, (error, uuid) => {
    if (error) {
      callback(COMMON_ERROR_MESSAGE);
    }
    sessionApi.login(username, password, uuid).then(response => {
      callback(null);
    }).catch(err => {
      let errorMessage;
      switch (err.response['status']) {
        case 400:
          errorMessage = COMMON_ERROR_MESSAGE;
          break;
        case 401:
        case 403:
          errorMessage = 'Your password is incorrect.';
          break;
        default:
          errorMessage = API_ERRORS[err.response['status']];
          break;
      }
      callback(errorMessage);
    });
  });
}

export function validateDomain() {
  return function (dispatch) {
    if (SessionUtils.isUpdateDomain) {
      getDomainObject(() => { })
    }
  }
}

export function resetLoginForm() {
  return { type: SESSION_ACTIONS.LOGIN_RESET }
}
